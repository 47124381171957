import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { ErrorMessage, Formik, Form } from 'formik'
import { Grid, Card, Box, CardContent, Button } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'

import { Layout, Field, Loader } from '../../../components'
import GoogleMap from '../../../components/map/map'
import {
  addBranch,
  clearBranchFlag,
  clearErrorMessageShow,
  getSingleBranch,
  updateBranch,
  getTypeWiseLookup,
} from '../../../redux/actions'
import { cancelHandler } from '../../../helpers/routeHelper'
import { SuccessNotify } from '../../../helpers/notification'

import './branch.scss'
import { Row, Col } from 'react-bootstrap'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'
import ErrorComponent from '../../../components/error/errorComponent'
import ErrorFocus from '../../../components/ws/ErrorFocus'
import { popUpMessage } from '../../../helpers/confirmationPopup'
import { getLatLngFromGeometric } from '../../../helpers/jsxHelper'
import { requireMessage, validationMessages, renderSelectOptions, trimmedValue } from '../../../helpers/string'
import { AddressInputTypeEnum, helpTextEnum, lookupTypes, MEDIA_NAME } from '../../../util/enums/enums'
import { ButtonComponent } from '../../../components/form/Button'
import { isValidMobileNumber } from '../../../helpers/validationHelper'
import SingleSelect from '../../../components/helper/singleSelect'
import { getAddMedia } from '../../../helpers/s3FileUpload'
import { stringValidation } from '../../../helpers/yupHelper'

class AddBranch extends Component {
  state = {
    latLng: {},
    branchInput: {
      branchName: '',
      contactName: '',
      mobileNumber: '',
      emailAddress: '',
      position: '',
      pricePerFt: '',
      address: [],
      image: '',
      addMedia: {
        image: [],
      },
      removeMedia: [],
    },

    isUpdate: false,
    editBranchId: '',

    mediaUpdated: false,

    formSubmitted: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      isAdded,
      isAddedError,
      history,
      clearBranchFlag,
      match: { params },
      isUpdated,
      isUpdateError,
    } = nextProps

    if (isAdded || isUpdated) {
      clearBranchFlag()
      setTimeout(() => SuccessNotify(`Branch is ${isUpdated ? 'updated' : 'added'} successfully`), 100)
      history.push('/manage-branches')
    } else if (isAddedError || isUpdateError) {
      return {
        formSubmitted: false,
      }
    } else if (params && params.id) {
      return {
        editBranchId: params.id,
        isUpdate: true,
      }
    }

    return null
  }

  componentDidMount() {
    const { editBranchId } = this.state
    const { clearBranchFlag, clearErrorMessageShow, getSingleBranch, getTypeWiseLookup } = this.props

    clearBranchFlag()
    clearErrorMessageShow()

    editBranchId && getSingleBranch(editBranchId)

    getTypeWiseLookup(lookupTypes.PROFESSION)
  }

  prepareValue = () => {
    const { branch } = this.props
    const { mediaUpdated } = this.state

    if (branch.id) {
      if (!mediaUpdated) {
        const { address } = branch

        const [oldAddress] = address

        this.setState({
          latLng: getLatLngFromGeometric(oldAddress.geometricLocation),
          mediaUpdated: true,
        })
      }

      return {
        id: branch.id,
        address: branch.address,
        branchName: branch.branchName,
        contactName: branch.contactName,
        mobileNumber: branch.mobileNumber,
        emailAddress: branch.emailAddress,
        position: branch.position ? { label: branch.position?.alias, value: branch.position?.id } : '',
        pricePerFt: branch.pricePerFt,
        image: branch.image,
        addMedia: {
          image: [],
        },
        removeMedia: [],
      }
    }
  }

  renderBranchInfo = (setFieldValue, values) => {
    const { professionTypes } = this.props

    return (
      <Card
        className="card-style m-0 border-top-right-radius-0 border-top-left-radius-0 border-bottom-right-radius-0 border-bottom-left-radius-0
      box-shadow-none map-div h-100"
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box fontSize={20} letterSpacing={1} fontWeight={600}>
                Branch Info
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Field
                label="Branch Name"
                type="text"
                limit={25}
                name="branchName"
                value={values.branchName}
                className="form-control"
                onChangeText={e => setFieldValue('branchName', e.target.value)}
                onBlur={e => setFieldValue('branchName', trimmedValue(e.target.value))}
                required
              />
              <ErrorMessage component="div" name="branchName" className="error-message" />
            </Grid>

            <Grid item xs={12}>
              <Field
                label="Contact Name"
                type="text"
                name="contactName"
                value={values.contactName}
                className="form-control"
                onChangeText={e => setFieldValue('contactName', e.target.value)}
                onBlur={e => setFieldValue('contactName', trimmedValue(e.target.value))}
                required
              />
              <ErrorMessage component="div" name="contactName" className="error-message" />
            </Grid>

            <Grid item xs={12}>
              <Field
                label="Mobile Number"
                helpText={helpTextEnum.surveyor.mobileNumber}
                type="mobile-number"
                placeholder=""
                id="mobileNumber"
                name="mobileNumber"
                value={values.mobileNumber}
                onChangeText={value => setFieldValue('mobileNumber', value)}
                required
                noFormControl
              />
              <ErrorMessage name="mobileNumber" component="div" className="error-message" />
            </Grid>

            <Grid item xs={12}>
              <Field
                label="Email Address"
                type="email"
                name="emailAddress"
                value={values.emailAddress}
                className="form-control"
                onChangeText={e => setFieldValue('emailAddress', e.target.value)}
                required
              />
              <ErrorMessage component="div" name="emailAddress" className="error-message" />
            </Grid>

            <Grid item xs={12}>
              <SingleSelect
                label="Profession"
                name="position"
                placeholder="Select Profession"
                selectedOption={values.position}
                options={renderSelectOptions(professionTypes, 'alias', 'id')}
                onChange={value => setFieldValue('position', value)}
                required
              />
              <ErrorMessage component="div" name="position" className="error-message" />
            </Grid>

            <Grid item xs={12}>
              <Field
                label="Price Per Ft ($)"
                helpText={helpTextEnum.priceInput}
                type="number"
                name="pricePerFt"
                value={values.pricePerFt}
                className="form-control"
                onChangeText={e => setFieldValue('pricePerFt', +e.target.value || '')}
                required
              />
              <ErrorMessage component="div" name="pricePerFt" className="error-message" />
            </Grid>

            <Grid item xs={12}>
              <Field
                label="Contact Image"
                name="image"
                id="image"
                type="single-image"
                onChangeText={setFieldValue}
                value={values.image}
                deleteIds={values.removeMedia}
                addMedia={values.addMedia}
                mediaName={MEDIA_NAME.BRANCHIMAGE}
                bigSize
                isNotCircle
                required
                maxImageSize={0}
              />
              <ErrorMessage component="div" name="image" className="error-message" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  render() {
    const { latLng, branchInput, isUpdate, editBranchId, formSubmitted } = this.state
    const {
      addBranch,
      history,
      errorMessage,
      isAddedError,
      isUpdateError,
      clearBranchFlag,
      clearErrorMessageShow,
      branch,
      updateBranch,
    } = this.props

    const initValue = isUpdate && editBranchId ? branch && branch.id && this.prepareValue() : branchInput
    return (
      <DashboardLayout className="add-branch-dashboard-layout">
        {isUpdate && branch && !branch.id ? (
          <Loader isPageLoader />
        ) : (
          <div className="pl-3 pr-3">
            <Formik
              initialValues={initValue}
              validationSchema={Yup.object().shape({
                address: Yup.array(
                  Yup.object({
                    city: Yup.string(),
                  })
                )
                  .compact(v => !v.city || !v.state || !v.country)
                  .required(requireMessage('Branch Address'))
                  .min(1, requireMessage('Branch Address'))
                  .ensure(),
                branchName: stringValidation.required(requireMessage('Branch name')),
                contactName: stringValidation.required(requireMessage('Contact name')),
                mobileNumber: Yup.string()
                  .test('match', validationMessages.validMobileNumber, val => isValidMobileNumber(val))
                  .required(requireMessage('Mobile number'))
                  .ensure(),
                emailAddress: Yup.string().email('Email is invalid').required(requireMessage('Email address')),
                position: Yup.string().required(requireMessage('Profession')),
                pricePerFt: Yup.number().required(requireMessage('Price per ft')),
                image: Yup.string().required(requireMessage('Branch image')),
              })}
              onSubmit={values => {
                this.setState({ formSubmitted: true })

                clearBranchFlag()
                clearErrorMessageShow()

                const { image, ...newValue } = values

                const updatedValue = {
                  ...newValue,
                  mobileNumber: isValidMobileNumber(newValue.mobileNumber, false),
                  position: newValue.position?.value,
                  addMedia: getAddMedia(newValue.addMedia),
                }

                if (isUpdate) {
                  updateBranch(updatedValue)
                } else {
                  addBranch(updatedValue)
                }
              }}
              render={({ errors, setFieldValue, values }) => (
                <Form>
                  <ErrorFocus />
                  <div className="pl-2 pt-3 pb-3 map-div map-title-bg">
                    <Box fontSize={20} letterSpacing={1} fontWeight={600} className="map-title">
                      Branch Location
                    </Box>
                  </div>

                  <Row className="m-0">
                    <Col xs={8} className="p-0 pr-3">
                      <div className="add-branch-map map-div-form">
                        <GoogleMap
                          className="add-branch-map"
                          latLng={latLng}
                          fetch={result => setFieldValue('address', [{ ...result, type: AddressInputTypeEnum.COMMON_ADDRESS }])}
                          height={40}
                          width={100}
                          placeHolder="Branch Location"
                          columnName="address"
                          isError={errors.address}
                          isUpdate={isUpdate && branch.hasOwnProperty('address') && branch.address}
                          isFullGrid
                          countryEdit
                        />
                      </div>
                    </Col>

                    <Col xs={4} className="p-0 ">
                      {this.renderBranchInfo(setFieldValue, values)}
                    </Col>
                  </Row>

                  {(isAddedError || isUpdateError) && errorMessage && popUpMessage(errorMessage[0].message, 'Error', undefined, undefined, undefined, () => {
                    clearBranchFlag()
                    clearErrorMessageShow()
                  })}

                  <div className="d-flex justify-content-center main--add--forms--btn mt-4">
                    <ButtonComponent
                      variant="outline"
                      color="success"
                      type="submit"
                      className=" h-auto btn--padding btn--font mr-3"
                      disabled={formSubmitted}
                      loader={formSubmitted}
                    >
                      {isUpdate ? 'Update' : 'Save'}
                    </ButtonComponent>

                    <ButtonComponent
                      variant="outline"
                      color="cancel"
                      className=" h-auto btn--padding btn--font"
                      onClick={() => cancelHandler(history)}
                    >
                      {' '}
                      {'Cancel'}
                    </ButtonComponent>
                  </div>
                </Form>
              )}
            ></Formik>
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  isAdded: state.branchReducer.isAdded,
  isAddedError: state.branchReducer.isAddedError,
  isUpdated: state.branchReducer.isUpdated,
  isUpdateError: state.branchReducer.isUpdateError,
  branch: state.branchReducer.branch,
  errorMessage: state.errorReducer.errorMessage,
  professionTypes: state.loginReducer.professionTypes,
})

const mapDispatchToProps = dispatch => ({
  addBranch: data => dispatch(addBranch(data)),
  clearBranchFlag: () => dispatch(clearBranchFlag()),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getSingleBranch: data => dispatch(getSingleBranch(data)),
  updateBranch: data => dispatch(updateBranch(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBranch)
