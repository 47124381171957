import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { Link } from 'react-router-dom'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { HomPageVideoStyle } from '../styleComponent/styleComponent'
import ReadMore from '../helper/truncate'
import { displayDefaultValue } from '../../helpers/string'
import VideoThumbnail from 'react-video-thumbnail'
import ReactPlayer from 'react-player'
import { dimension } from '../../util/enums/enums'
import VideoPlayer from '../../containers/sm/components/post/video-player'

export const VideoComponent = React.memo(({ video, setVideoUrlHandler, videoWidth, videoHeight, className, isBoatMedia }) => {
  const [finalThumb, setFinalThumb] = useState('')

  const [played, setPlayed] = useState(false)

  useEffect(() => {
    setFinalThumb((video.thumbnail && video.thumbnail[0]?.url) || finalThumb)
  }, [video])

  const createMarkup = text => {
    return { __html: text }
  }

  return (
    <Grid item xs={12} sm={4} className="video-common-layout video--padding--grid">
      <div className="react-thumbnail-generator-wrap" onClick={() => setVideoUrlHandler(finalThumb)}>
        <VideoThumbnail videoUrl={video.url[0].url} thumbnailHandler={thumb => setFinalThumb(finalThumb || thumb)} />
        <PlayCircleOutlineIcon className="playVideo-icon" />
      </div>

      <div className={`${className} position-relative overflow-hidden cursor-pointer d-flex`}>
        {!played ? (
          <HomPageVideoStyle
            img={encodeURI(finalThumb)}
            className="back--ground-set"
            onClick={() => setPlayed(true)}
          >
            <PlayCircleOutlineIcon className="playVideo-icon" />
          </HomPageVideoStyle>
        ) : (
          <VideoPlayer
            src={video.url[0].url}
            autoPlay
            controls
            style={{
              width: '100%',
              height: '100%',
              aspectRatio: '16/9',
              background: 'black',
            }}
          />
        )}
      </div>

      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mt-3 mb-1 text-capitalize media--video--none video--common--title">{video.title}</h4>
          {isBoatMedia && <span className="mt-3 mb-1">{/* moment(date).format("MMMM DD, YYYY") */}</span>}
        </div>

        {/* <div dangerouslySetInnerHTML={{ __html: video.description }} /> */}
        <p className="text-justify light-silver f-15 mt-2 mb-2 article-desc media--article--desc">
          <ReadMore className="text-justify" lines={3}>
            <span dangerouslySetInnerHTML={createMarkup(displayDefaultValue(video.description))}></span>
          </ReadMore>
        </p>
      </div>
    </Grid>
  )
})
