import React, { Component } from 'react'

import { renderErrorMessage } from '../../helpers/jsxHelper'
import { passwordStrengthEnum } from '../../util/enums/enums'

class PasswordValidation extends Component {
  render() {
    const { errorMessages, passwordStrength } = this.props
    return (
      <div className="mt-2 register-password-hint">
        {passwordStrength
          ? passwordStrength === passwordStrengthEnum.POOR || passwordStrength === passwordStrengthEnum.WEAK
            ? renderErrorMessage(`Password Strength:`, 'error-message mt-0', true, passwordStrength)
            : renderErrorMessage(`Password Strength:`, 'error-message-fullfill', false, passwordStrength)
          : ''}
        {errorMessages && errorMessages.passwordLength
          ? renderErrorMessage('At least 8 characters', 'error-message mt-0', true)
          : renderErrorMessage('At least 8 characters', 'error-message-fullfill', false)}
        {errorMessages && errorMessages.symbolOrNumberCount
          ? renderErrorMessage('At least 1 symbol & Number', 'error-message mt-0', true)
          : renderErrorMessage('At least 1 symbol & Number', 'error-message-fullfill', false)}
        {errorMessages && errorMessages.capsCount
          ? renderErrorMessage('Password must contain one capital', 'error-message mt-0', true)
          : renderErrorMessage('Password must contain one capital', 'error-message-fullfill', false)}
      </div>
    )
  }
}

export default PasswordValidation
PasswordValidation.defaultProps = {
  passwordStrength: '',
}
