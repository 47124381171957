import React, { Component, Fragment } from 'react'
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, MenuItem, Box } from '@material-ui/core'
import { connect } from 'react-redux'
import { Formik, ErrorMessage, Form } from 'formik'
import * as Yup from 'yup'
import { Card, Container, Row, Col } from 'react-bootstrap'

import {
  clearMarinaFlag,
  createMarinaStorage,
  getSingleMarina,
  updateMarina,
  clearEditMarinaFlag,
  getMoreServices,
} from '../../../redux/actions/marinaAndStorageAction'
import { Field, Loader } from '../../../components'
import GoogleMap from '../../../components/map/map'

import '../../../components/marinaStorage/marinaStorage.scss'
import {
  renderSelectOptions,
  getIds,
  requireMessage,
  requiredWords,
  validateWords,
  trimmedValue,
  maximumWordCount,
} from '../../../helpers/string'
import { getTypeWiseLookup } from '../../../redux/actions/marinaAndStorageAction'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'
import MultiSelect from '../../../components/helper/multiSelect'

import '../../../styles/common.scss'
import ErrorComponent from '../../../components/error/errorComponent'
import { clearErrorMessageShow } from '../../../redux/actions'
import ErrorFocus from '../../../components/ws/ErrorFocus'
import { getLatLngFromGeometric } from '../../../helpers/jsxHelper'
import { helpTextEnum, AddressInputTypeEnum, marinaLayoutTypes, MEDIA_NAME } from '../../../util/enums/enums'
import { CommonModal } from '../../../components/modal/commonModal'
import { popUpMessage } from '../../../helpers/confirmationPopup'
import { ButtonComponent } from '../../../components/form/Button'
import { getAddMedia } from '../../../helpers/s3FileUpload'
import { stringValidation, urlValidation } from '../../../helpers/yupHelper'
import TagsInput from '../manageBoats/TagsInput'
import { clear_getAgreement, getAgreement } from '../../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'

class addMarinaStorage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      latLng: {},
      selected: [],
      editMarinaId: props.location?.state?.id,
      marianAndStorage: {
        address: [],
        provider: '',
        noneMembersRate: '',
        membershipBenefits: '',
        // memberEvents: '',
        promotionRunning: '',
        // exclusiveFacilities: '',
        // serviceDescription: '',
        // membersReview: '',
        // newsEvents: '',
        berth: '',
        facilities: '',
        storage: '',
        membersRate: '',
        serviceProvide: [],
        images: [],
        addMedia: {
          images: [],
          dockLayout: [],
          storageLayout: [],
        },
        removeMedia: [],
        dockLayout: [],
        storageLayout: [],
        name: '',
        meta_tags: null,
      },
      mediaUpdated: false,
      infoContact: false,
      infoVideo: false,
      agree: true,
      termsModal: false,
      isButtonLoader: false,
      userRole: '',
    }
  }

  componentDidMount() {
    const { getMoreServices, getTypeWiseLookup, getSingleMarina, location } = this.props
    const { editMarinaId } = this.state

    getMoreServices({ isAdmin: true })
    getTypeWiseLookup('You are An')
    editMarinaId && getSingleMarina(editMarinaId)
  }

  onClickAgree = () => {
    this.setState(prevState => ({
      agree: !prevState.agree,
    }))
  }

  termsHandler = () => {
    this.setState(prevState => ({
      termsModal: !prevState.termsModal,
    }))
  }

  static getDerivedStateFromProps(props, prevState) {
    const {
      createSuccess,
      clearMarinaFlag,
      history,
      singleSuccess,
      updateSuccess,
      clearEditMarinaFlag,
      updateError,
      createError,
      currentUser,
      getAgreement,
      clear_getAgreement,
      agreement,
    } = props

    if (currentUser?.role?.aliasName && currentUser?.role?.aliasName !== prevState.userRole) {
      getAgreement({ role: currentUser?.role?.aliasName })

      return {
        ...prevState,
        userRole: currentUser?.role?.aliasName,
      }
    }

    if (agreement?.success || agreement?.failure) {
      clear_getAgreement()
      return prevState
    }

    if (createSuccess || updateSuccess) {
      history.push('/manage-marina-storage')
      return {
        isButtonLoader: false,
      }
    }
    if (createError || updateError) {
      return {
        isButtonLoader: false,
      }
    }
    if (singleSuccess) {
      clearMarinaFlag()
    }

    return null
  }

  cancelHandler = () => {
    const { history, clearEditMarinaFlag } = this.props
    clearEditMarinaFlag()
    history.push('/manage-marina-storage')
  }

  menuItems(values) {
    if (values) {
      return values.map(name => (
        <MenuItem key={name.alias} value={name.alias}>
          {name.alias}
        </MenuItem>
      ))
    }
  }

  renderImageUploader = (provider, type) => {
    const { typeWiseLookup } = this.props
    const { editMarinaId } = this.state

    const providerData = editMarinaId
      ? provider
      : provider && typeWiseLookup && typeWiseLookup.length && typeWiseLookup.find(item => item.id === provider)
    if (provider && providerData.alias === marinaLayoutTypes.BOTH) {
      return true
    } else if (provider && providerData.alias === type) {
      return true
    }

    return false
  }

  renderRadioItems = items => {
    return items.map(item => {
      return <FormControlLabel key={item.id} label={item.alias} control={<Radio value={item.id} />} />
    })
  }

  handleChange = e => {
    this.setState({ selected: e.target.value })
  }

  prepareMarinaValues = () => {
    const { editMarina } = this.props
    const { marianAndStorage, mediaUpdated, editMarinaId } = this.state
    if (editMarinaId && editMarina.hasOwnProperty('id')) {
      if (!mediaUpdated) {
        const {
          address,
          provider,
          noneMembersRate,
          membershipBenefits,
          // memberEvents,
          promotionRunning,
          // exclusiveFacilities,
          // serviceDescription,
          // membersReview,
          // newsEvents,
          berth,
          facilities,
          storage,
          membersRate,
          serviceProvide,
          images,
        } = editMarina

        const [oldAddress] = address

        marianAndStorage.provider = provider
        marianAndStorage.noneMembersRate = noneMembersRate
        marianAndStorage.membershipBenefits = membershipBenefits
        // marianAndStorage.memberEvents = memberEvents
        marianAndStorage.promotionRunning = promotionRunning
        // marianAndStorage.exclusiveFacilities = exclusiveFacilities
        // marianAndStorage.membersReview = membersReview
        // marianAndStorage.serviceDescription = serviceDescription
        // marianAndStorage.newsEvents = newsEvents
        marianAndStorage.berth = berth
        marianAndStorage.facilities = facilities
        marianAndStorage.storage = storage
        marianAndStorage.membersRate = membersRate
        marianAndStorage.images = images

        marianAndStorage.serviceProvide = renderSelectOptions(serviceProvide, 'name', 'id')

        this.setState({
          marianAndStorage,
          latLng: getLatLngFromGeometric(oldAddress.geometricLocation),
          mediaUpdated: true,
        })
      }

      const { createdAt, pageVisits, likes, __typename, accountStatus, reviews, user, adStatus, ...filteredEditMarina } =
        editMarina

      if ('__typename' in get(filteredEditMarina, 'address[0]', {})) {
        delete filteredEditMarina.address[0].__typename
      }
      if ('__typename' in get(filteredEditMarina, 'address[0].geometricLocation', {})) {
        delete filteredEditMarina.address[0].geometricLocation.__typename
      }

      return {
        ...filteredEditMarina,
        serviceProvide: marianAndStorage.serviceProvide,
        addMedia: marianAndStorage.addMedia,
        removeMedia: [],
      }
    }
  }

  render() {
    const {
      typeWiseLookup,
      editMarina,
      isLoading,
      createError,
      updateError,
      errorMessage,
      clearErrorMessageShow,
      clearMarinaFlag,
      agreement: agreementContent,
    } = this.props

    let { moreMarinaService } = this.props
    moreMarinaService = renderSelectOptions(moreMarinaService, 'name', 'id')

    const { latLng, marianAndStorage, editMarinaId, mediaUpdated, agree, termsModal, isButtonLoader } = this.state

    const initValue =
      editMarinaId && editMarina && editMarina.hasOwnProperty('id') ? this.prepareMarinaValues() : marianAndStorage

    return (
      <Fragment>
        <DashboardLayout>
          {isLoading ? (
            <Loader isPageLoader />
          ) : (
            <Formik
              initialValues={{ ...initValue }}
              onSubmit={values => {
                this.setState({ isButtonLoader: true })
                const { createMarinaStorage, updateMarina } = this.props
                const { editMarinaId } = this.state

                if (!agree) {
                  popUpMessage('Please agree terms & conditions.', 'Terms & Agreement')
                  this.setState({ isButtonLoader: false })
                  return
                } else {
                  const { images, dockLayout, storageLayout, ...newValues } = values
                  const input = {
                    ...newValues,
                    serviceProvide: getIds(newValues.serviceProvide),
                    addMedia: getAddMedia(newValues.addMedia),
                  }

                  if (editMarinaId) {
                    clearMarinaFlag()
                    clearErrorMessageShow()
                    updateMarina({ ...input, provider: newValues.provider?.id ? newValues.provider.id : values.provider })
                  } else {
                    clearMarinaFlag()
                    clearErrorMessageShow()
                    createMarinaStorage(input)
                  }
                }
              }}
              validationSchema={Yup.object().shape({
                name: stringValidation.required('Facility Name is required.').max(50, 'Max. 50 characters allowed'),
                address: Yup.array(
                  Yup.object({
                    city: Yup.string(),
                  })
                )
                  .compact(v => !v.city || !v.state || !v.country)
                  .required(requireMessage('Marina & Storage Location'))
                  .min(1, requireMessage('Marina & Storage Location'))
                  .ensure(),
                provider: Yup.string().required('Provider field is required.'),
                membershipBenefits: stringValidation.required('Membership Benefit field is required.').max(400, 'Max. 400 characters allowed'),
                // memberEvents: stringValidation.required('Members Only Events field is required.'),
                membersRate: stringValidation.required(requireMessage('Members Rate')).max(400, 'Max. 400 characters allowed'),
                noneMembersRate: stringValidation.required(requireMessage('None Members Rate')).max(400, 'Max. 400 characters allowed'),
                // serviceDescription: stringValidation.required('Service Description field is required.'),
                promotionRunning: stringValidation.max(400, 'Max. 400 characters allowed'),
                // exclusiveFacilities: stringValidation
                //   .required('Exclusive Facilities field is required.')
                //   .ensure()
                //   .test('match', requiredWords('Exclusive Facilities'), val => validateWords(val)),
                facilities: stringValidation
                  .required('Description of Our Service is required.')
                  .ensure()
                  .max(400, 'Max. 400 characters allowed')
                  .test('match', requiredWords('Description of Our Service'), val => validateWords(val)),
                // membersReview: stringValidation.required('What Our Members Telling About Us field is required.'),
                // newsEvents: stringValidation.required('News and Events field is required.'),
                berth: stringValidation.required('Our Berth field is required.').max(400, 'Max. 400 characters allowed'),
                storage: stringValidation.required('Our Storage field is required.').max(400, 'Max. 400 characters allowed'),
                images: Yup.array().min(5, 'Minimum 5 photos upload required').max(15, 'You can add up to 15 images'),
                serviceProvide: Yup.array().min(4, 'Minimum 4 service we provide select required'),
                dockLayout: Yup.array().max(10, 'You can add up to 10 images'),
                storageLayout: Yup.array().max(10, 'You can add up to 10 images'),
                youtubeLinkVideo: urlValidation,
              })}
              render={({ errors, values, setFieldValue, handleSubmit }) => (
                <div>
                  <Form>
                    <ErrorFocus />
                    <Container fluid={true}>
                      <div className="pl-2 pt-3 pb-3">
                        <Box fontSize={24} fontWeight={700}>
                          List your Marina and Storage
                          {values?.adId && <div className="font-weight-400 font-14">Ad ID: {`${values.adId}`}</div>}
                        </Box>
                      </div>

                      <Row className="mr-0 ml-0 mb-3 align--flex--start">
                        <Col md={5} className="map-div-form pl-0 pr-0 map-not-padding">
                          <Row>
                            <Col xs={12} className={values?.adId ? 'm-2' : ''}>
                              <GoogleMap
                                latLng={latLng}
                                fetch={result => {
                                  if (result?.address1) {
                                    setFieldValue('address', [{ ...result, type: AddressInputTypeEnum.COMMON_ADDRESS }])
                                  } else {
                                    setFieldValue('address', [])
                                  }
                                }}
                                height={38}
                                width={100}
                                placeHolder={'Marina and Storage Location'}
                                columnName={'address'}
                                isError={errors.address}
                                isUpdate={editMarinaId}
                                isFullGrid
                                crossCountryAllowed
                              />
                            </Col>
                            <Col xs={12}>
                              <div className="p-10">
                                <Row className="m-0">
                                  <Col
                                    className="marina--type--img--margin overflow-hidden"
                                    xs={this.renderImageUploader(values.provider, marinaLayoutTypes.BOTH) ? 12 : 6}
                                  >
                                    <Field
                                      label="Upload Images"
                                      id="images"
                                      name="images"
                                      type="multi-upload-image"
                                      setFieldValue={setFieldValue}
                                      values={values.images}
                                      deleteIds={values.removeMedia}
                                      addMedia={values.addMedia}
                                      mediaName={MEDIA_NAME.MARINAIMAGES}
                                      required
                                      maxFiles={15}
                                    />
                                    <ErrorMessage component="div" name="images" className="error-message text-center mt-0" />
                                  </Col>
                                  {this.renderImageUploader(values.provider, marinaLayoutTypes.BOTH) && (
                                    <Col xs={12} className="mb-3">
                                      <hr></hr>
                                    </Col>
                                  )}
                                  {this.renderImageUploader(values.provider, marinaLayoutTypes.DOCK) && (
                                    <Col className="overflow-hidden" xs={6}>
                                      <div className="d-flex flex-wrap justify-content-center">
                                        <div className="addBoatShow-imgUploader">
                                          <Field
                                            label="Dock Layout"
                                            helpText={helpTextEnum.marina.dockLayout}
                                            id="dockLayout"
                                            name="dockLayout"
                                            type="multi-upload-image"
                                            setFieldValue={setFieldValue}
                                            values={values.dockLayout}
                                            deleteIds={values.removeMedia}
                                            addMedia={values.addMedia}
                                            mediaName={MEDIA_NAME.MARINADOCKLAYOUT}
                                            maxFiles={10}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                  {this.renderImageUploader(values.provider, marinaLayoutTypes.STORAGE) && (
                                    <Col className="overflow-hidden" xs={6}>
                                      <div className="d-flex flex-wrap justify-content-center">
                                        <div className="addBoatShow-imgUploader">
                                          <Field
                                            label="Storage Layout"
                                            helpText={helpTextEnum.marina.storageLayout}
                                            id="storageLayout"
                                            name="storageLayout"
                                            type="multi-upload-image"
                                            setFieldValue={setFieldValue}
                                            values={values.storageLayout}
                                            deleteIds={values.removeMedia}
                                            addMedia={values.addMedia}
                                            mediaName={MEDIA_NAME.MARINASTORAGELAYOUT}
                                            maxFiles={10}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  <Col xs={12} className="mb-3">
                                    <Field
                                      label="Add video link"
                                      labelUnder="You can add Youtube, Facebook, Daily Motion, Streamable, Google Videos, and Vimeo"
                                      name="youtubeLinkVideo"
                                      value={values.youtubeLinkVideo}
                                      type="text"
                                      className="form-control"
                                      onChangeText={e => setFieldValue('youtubeLinkVideo', e.target.value)}
                                    />
                                    <ErrorMessage component="div" name="youtubeLinkVideo" className="error-message" />
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col md={7} className="pr-0 pl-3">
                          <div className="p-10">
                            {/* <Card.Title className="ml-0">Information</Card.Title> */}
                            <Row className="dashboard-forms-field">
                              <Col xs={12} className="mb-3">
                                <FormLabel className="addMarinaStorage-info" component="legend">
                                  <label className="required">We are</label>
                                </FormLabel>

                                <div className="mt-2 circular-radio-group">
                                  {typeWiseLookup.map((item, idx) => (
                                    <div
                                      className={
                                        'circular-radio-item' +
                                        (item.id === (values.provider?.id || values.provider)
                                          ? ' circular-radio-item-active'
                                          : '')
                                      }
                                      onClick={() => setFieldValue('provider', item.id)}
                                    >
                                      {item.alias}
                                    </div>
                                  ))}
                                </div>
                                <ErrorMessage component="div" name="provider" className="error-message mt-0 mb-4" />
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="mb-3">
                                  <Field
                                    required
                                    label="Facility Name"
                                    id={'name'}
                                    name={'name'}
                                    value={values.name}
                                    type="text"
                                    onChangeText={e => {
                                      setFieldValue('name', (e.target.value).substring(0, 50))
                                    }}
                                    onBlur={e => setFieldValue('name', trimmedValue(e.target.value))}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="name" className="error-message" /></span>
                                    <span>{values.name?.length || 0}/50</span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="dashboard-forms-field">
                              <Col xs={12}>
                                <div className="mb-3">
                                  <Field
                                    required
                                    label="Membership Benefit"
                                    id={'membershipBenefits'}
                                    name={'membershipBenefits'}
                                    value={values.membershipBenefits}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('membershipBenefits', (e.target.value).substring(0, 400))
                                    }}
                                    onBlur={e => setFieldValue('membershipBenefits', trimmedValue(e.target.value))}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="membershipBenefits" className="error-message" /></span>
                                    <span>{values.membershipBenefits?.length || 0}/400</span>
                                  </div>
                                </div>
                                {/* <div className="mb-3">
                                  <Field
                                    required
                                    label="Members Only Events"
                                    id={'memberEvents'}
                                    name={'memberEvents'}
                                    value={values.memberEvents}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('memberEvents', e.target.value)
                                    }}
                                    onBlur={e => setFieldValue('memberEvents', trimmedValue(e.target.value))}
                                    onKeyDown={maximumWordCount}
                                  />
                                  <ErrorMessage component="div" name="memberEvents" className="error-message" />
                                </div> */}
                                {/* <div className="mb-3">
                                  <Field
                                    required
                                    label="Exclusive Facilities"
                                    id={'exclusiveFacilities'}
                                    name={'exclusiveFacilities'}
                                    value={values.exclusiveFacilities}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('exclusiveFacilities', e.target.value)
                                    }}
                                    onBlur={e => setFieldValue('exclusiveFacilities', trimmedValue(e.target.value))}
                                    onKeyDown={maximumWordCount}
                                  />
                                  <ErrorMessage component="div" name="exclusiveFacilities" className="error-message" />
                                </div> */}
                                <div className="mb-3">
                                  <Field
                                    required
                                    label="Description of Our Service"
                                    id={'facilities'}
                                    name={'facilities'}
                                    value={values.facilities}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('facilities', (e.target.value).substring(0, 400))
                                    }}
                                    onBlur={e => setFieldValue('facilities', trimmedValue(e.target.value))}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="facilities" className="error-message" /></span>
                                    <span>{values.facilities?.length || 0}/400</span>
                                  </div>
                                </div>
                                {/* <div className="mb-3">
                                  <Field
                                    label="Our News And Events"
                                    id={'newsEvents'}
                                    name={'newsEvents'}
                                    value={values.newsEvents}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('newsEvents', e.target.value)
                                    }}
                                    required
                                    onBlur={e => setFieldValue('newsEvents', trimmedValue(e.target.value))}
                                    onKeyDown={maximumWordCount}
                                  />
                                  <ErrorMessage component="div" name="newsEvents" className="error-message" />
                                </div> */}
                                <div className="mb-3">
                                  <Field
                                    label="Members Rates"
                                    id={'membersRate'}
                                    name={'membersRate'}
                                    value={values.membersRate}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('membersRate', (e.target.value).substring(0, 400))
                                    }}
                                    required
                                    onBlur={e => setFieldValue('membersRate', trimmedValue(e.target.value))}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="membersRate" className="error-message" /></span>
                                    <span>{values.membersRate?.length || 0}/400</span>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12}>
                                <div className="mb-3">
                                  <Field
                                    label="None Members Rates"
                                    id={'noneMembersRate'}
                                    value={values.noneMembersRate}
                                    name={'noneMembersRate'}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('noneMembersRate', (e.target.value).substring(0, 400))
                                    }}
                                    required
                                    onBlur={e => setFieldValue('noneMembersRate', trimmedValue(e.target.value))}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="noneMembersRate" className="error-message" /></span>
                                    <span>{values.noneMembersRate?.length || 0}/400</span>
                                  </div>
                                </div>
                                {/* <div className="mb-3">
                                  <Field
                                    required
                                    label="Service We Provide"
                                    id={'serviceDescription'}
                                    name={'serviceDescription'}
                                    value={values.serviceDescription}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('serviceDescription', (e.target.value).substring(0, 400))
                                    }}
                                    onBlur={e => setFieldValue('serviceDescription', trimmedValue(e.target.value))}
                                    onKeyDown={maximumWordCount}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="serviceDescription" className="error-message" /></span>
                                    <span>{values.serviceDescription?.length || 0}/400</span>
                                  </div>
                                </div> */}
                                <div className="mb-3">
                                  <Field
                                    label="Running Promotion"
                                    id={'promotionRunning'}
                                    name={'promotionRunning'}
                                    value={values.promotionRunning}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('promotionRunning', (e.target.value).substring(0, 400))
                                    }}
                                    onBlur={e => setFieldValue('promotionRunning', trimmedValue(e.target.value))}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="promotionRunning" className="error-message" /></span>
                                    <span>{values.promotionRunning?.length || 0}/400</span>
                                  </div>
                                </div>
                                {/* <div className="mb-3">
                                  <Field
                                    required
                                    label="What Our Members Telling About Us"
                                    id={'membersReview'}
                                    name={'membersReview'}
                                    value={values.membersReview}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('membersReview', e.target.value)
                                    }}
                                    onBlur={e => setFieldValue('membersReview', trimmedValue(e.target.value))}
                                    onKeyDown={maximumWordCount}
                                  />
                                  <ErrorMessage component="div" name="membersReview" className="error-message" />
                                </div> */}
                                <div className="mb-3">
                                  <Field
                                    required
                                    label="Our Berth"
                                    id={'berth'}
                                    name={'berth'}
                                    value={values.berth}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('berth', (e.target.value).substring(0, 400))
                                    }}
                                    onBlur={e => setFieldValue('berth', trimmedValue(e.target.value))}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="berth" className="error-message" /></span>
                                    <span>{values.berth?.length || 0}/400</span>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Field
                                    required
                                    label="Our Storage"
                                    id={'storage'}
                                    name={'storage'}
                                    type="textarea"
                                    value={values.storage}
                                    onChangeText={e => {
                                      setFieldValue('storage', (e.target.value).substring(0, 400))
                                    }}
                                    onBlur={e => setFieldValue('storage', trimmedValue(e.target.value))}
                                  />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span><ErrorMessage component="div" name="storage" className="error-message" /></span>
                                    <span>{values.storage?.length || 0}/400</span>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} className="mb-3">
                                <label className="required">Service We Provide</label>
                                <div className="d-flex justify-content-between w-100 flex-column">
                                  <FormControl className="select-container w-100">
                                    <MultiSelect
                                      helpText={helpTextEnum.multiSelect}
                                      selectedOption={values.serviceProvide}
                                      onChangeValue={item => {
                                        setFieldValue('serviceProvide', [...item])
                                      }}
                                      options={moreMarinaService}
                                    />
                                  </FormControl>
                                  <ErrorMessage component="div" name="serviceProvide" className="error-message" />
                                </div>
                              </Col>
                              <Col xs={12}>
                                <TagsInput
                                  value={values?.meta_tags}
                                  handleChange={value => {
                                    setFieldValue('meta_tags', value.join(','))
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      {/* {agreementContent?.data && (
                        <div className="clearfix mt-3 checkbox--with--align-label">
                          <div className="custom-control no-zIndex custom-checkbox float-left mb-none">
                            <input
                              type="checkbox"
                              className="custom-control-input custom-control--agreement"
                              id="agree"
                              checked={agree}
                              onClick={() => {
                                this.onClickAgree()
                              }}
                            />
                            <label className="custom-control-label font-14 register-custom-control-label mb-0 d-flex cursor-pointer">
                              <div>
                                <span onClick={this.onClickAgree}>I have read and agree to</span>
                                <span className="ml-1 darkBlue mr-1">
                                  <span onClick={this.termsHandler} className="darkBlue">
                                    {agreementContent.data.title}
                                  </span>
                                </span>
                                <span onClick={this.onClickAgree}>
                                  and understand that this application is to add used Marina with AdamSea.Com.
                                </span>
                              </div>

                              <CommonModal
                                open={termsModal}
                                close={this.termsHandler}
                                title={agreementContent.data.title}
                                className="agreement--service--modal--section"
                              >
                                <div dangerouslySetInnerHTML={{ __html: agreementContent.data.pageContent }} />
                              </CommonModal>
                            </label>
                          </div>
                        </div>
                      )} */}

                      {createError || updateError ? <ErrorComponent errors={errorMessage} /> : <> </>}
                      <div className="mt-5 d-flex justify-content-center mb-5">
                        <button
                          className="btn btn-lg btn-o-black mr-3"
                          onClick={handleSubmit}
                          disabled={isButtonLoader}
                          loader={isButtonLoader}
                        >
                          {editMarinaId ? 'Update' : 'List'}
                        </button>

                        <button className="btn btn-lg btn-o-black" onClick={this.cancelHandler}>
                          Cancel
                        </button>
                      </div>
                    </Container>
                  </Form>
                </div>
              )}
            ></Formik>
          )}
        </DashboardLayout>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  createSuccess: state.marinaAndStorageReducer.createSuccess,
  createError: state.marinaAndStorageReducer.createError,
  typeWiseLookup: state.marinaAndStorageReducer && state.marinaAndStorageReducer.marinaTypeWiseLookUps,
  editMarina: state.marinaAndStorageReducer && state.marinaAndStorageReducer.editMarina,
  isLoading: state.marinaAndStorageReducer && state.marinaAndStorageReducer.isLoading,
  singleSuccess: state.marinaAndStorageReducer && state.marinaAndStorageReducer.singleSuccess,
  updateSuccess: state.marinaAndStorageReducer && state.marinaAndStorageReducer.updateSuccess,
  updateError: state.marinaAndStorageReducer && state.marinaAndStorageReducer.updateError,
  moreMarinaService: state.marinaAndStorageReducer.moreMarinaService,
  errorMessage: state.errorReducer && state.errorReducer.errorMessage,
  currentUser: state.loginReducer.currentUser,
  agreement: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.agreement,
})

const mapDispatchToProps = dispatch => ({
  clearMarinaFlag: () => dispatch(clearMarinaFlag()),
  createMarinaStorage: data => dispatch(createMarinaStorage(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
  getSingleMarina: data => dispatch(getSingleMarina(data)),
  updateMarina: data => dispatch(updateMarina(data)),
  clearEditMarinaFlag: () => dispatch(clearEditMarinaFlag()),
  getMoreServices: data => dispatch(getMoreServices(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getAgreement: data => dispatch(getAgreement(data)),
  clear_getAgreement: () => dispatch(clear_getAgreement()),
})

export default connect(mapStateToProps, mapDispatchToProps)(addMarinaStorage)
