import {
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  CLEAR_AUTH_FLAG,
  USER_LOGOUT_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  CLEAR_UPDATE_FLAG,
  GET_USER_ROLES,
  GET_USER_ROLES_SUCCESS,
  GET_USER_BY_ID_SUCCESS,
  SET_CURRENT_LOCATION,
  FORGOT_PASSWORD_MAIL_FAILURE,
  FORGOT_PASSWORD_MAIL_SUCCESS,
  CLEAR_FORGOT_PASSWORD_MAIL_FLAG,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  CLEAR_RESET_PASSWORD_FLAG,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD,
  CLEAR_PASSWORD_FLAG,
  GET_ALL_PAGE_INFO_BY_TYPE,
  GET_ALL_PAGE_INFO_BY_TYPE_SUCCESS,
  GET_ALL_PAGE_INFO_BY_TYPE_FAILURE,
  CHANGE_USER_ACCOUNT_STATUS,
  CHANGE_USER_ACCOUNT_STATUS_SUCCESS,
  CHANGE_USER_ACCOUNT_STATUS_FAILURE,
  GET_USER_LOCATION,
  GET_USER_LOCATION_SUCCESS,
  GET_USER_LOCATION_FAILURE,
  USER_REGISTER,
  USER_LOGIN,
  GET_TYPE_WISE_LOOKUP,
  GET_TYPE_WISE_LOOKUP_SUCCESS,
  GET_USER_BY_ID,
  GET_USER_PAYMENT_INFO,
  GET_USER_PAYMENT_INFO_SUCCESS,
  GET_USER_PAYMENT_INFO_FAILURE,
  ADD_USER_PAYMENT_INFO,
  ADD_USER_PAYMENT_INFO_SUCCESS,
  ADD_USER_PAYMENT_INFO_FAILURE,
  CLEAR_USER_PAYMENT_INFO_FLAG,
  UPDATE_EMAIL_SUCCESS,
  USER_EMAIL_VERIFY_SUCCESS,
  CLEAR_USER_VERIFY_FLAGS,
  ADD_REQUEST_FOR_AGREEMENT_ID,
  ADD_REQUEST_FOR_AGREEMENT_ID_SUCCESS,
  ADD_REQUEST_FOR_AGREEMENT_ID_FAILURE,
  CLEAR_AGREEMENT_ID_REQUEST_FLAG,
  GET_USER_BY_AGREEMENT_ID,
  GET_USER_BY_AGREEMENT_ID_SUCCESS,
  GET_USER_BY_AGREEMENT_ID_FAILURE,
  REMOVE_AGREEMENT_ID_DATA,
  CLEAR_REMOVE_AGREEMENT_ID_FLAG,
  USER_EMAIL_VERIFY,
  USER_EMAIL_VERIFY_FAILURE,
  ADMIN_LOGIN_AS_USER,
  ADMIN_LOGIN_AS_USER_SUCCESS,
  ADMIN_LOGIN_AS_USER_FAILURE,
  CLEAR_ADMIN_LOGIN_FLAG,
  CLEAR_DOCUMENT_URL,
  GET_DOCUMENT_URL,
  GET_DOCUMENT_URL_SUCCESS,
  GET_DOCUMENT_URL_FAILURE,
  GET_USER_BY_ID_FAILURE,
  GET_ITEMS_BY_USER,
  GET_ITEMS_BY_USER_SUCCESS,
  GET_ITEMS_BY_USER_FAILURE,
  GET_SITE_CONFIGURATIONS,
  GET_SITE_CONFIGURATIONS_SUCCESS,
  GET_SITE_CONFIGURATIONS_FAILURE,
  CLEAR_AGREEMENT_CREATE_MESSAGE,
  CLEAR_USER_REGISTER_FLAG,
  SET_DEALER_IN_MANUFACTURER,
  GET_DEALERS_REQUESTS,
  SET_MANUFACTURER,
  GET_ALL_DEALERS,
  GET_MANAGE_PASSWORD_CONTENT_SUCCESS,
  GET_MANAGE_PASSWORD_CONTENT_FAILURE,
  GET_MANAGE_PASSWORD_CONTENT,
  CLEAR_MANUFACTURER_FLAG,
  ACCEPT_OR_REJECT_DEALER_REQUEST_SUCCESS,
  SUBSCRIBE_USER,
  SUBSCRIBE_USER_SUCCESS,
  SUBSCRIBE_USER_FAILURE,
  ACCEPT_OR_REJECT_DEALER_REQUEST,
  ACCEPT_OR_REJECT_DEALER_REQUEST_FAILURE,
  GET_TRANSACTION_BY_USER,
  GET_TRANSACTION_BY_USER_SUCCESS,
  GET_TRANSACTION_BY_USER_FAILURE,
  FETCH_ALL_CHANNELS,
  FETCH_ALL_CHANNELS_SUCCESS,
  FETCH_ALL_CHANNELS_FAILURE,
  ADD_REMOVE_FRIEND,
  ADD_REMOVE_FRIEND_SUCCESS,
  ADD_REMOVE_FRIEND_FAILURE,
  GET_USER_STRIPE_DETAILS,
  GET_USER_STRIPE_DETAILS_SUCCESS,
  GET_USER_STRIPE_DETAILS_FAILURE,
  UPDATE_USER_STRIPE_PROFILE_COMPLETED,
  UPDATE_USER_STRIPE_PROFILE_COMPLETED_SUCCESS,
  UPDATE_USER_STRIPE_PROFILE_COMPLETED_FAILURE,
  CLEAR_USER_STRIPE_LINK,
  GET_TRANSACTION_BY_USER_PAYOUTS,
  GET_TRANSACTION_BY_USER_PAYOUTS_SUCCESS,
  GET_TRANSACTION_BY_USER_PAYOUTS_FAILURE,
  SET_COOKIE_POLICY_STATUS,
  TOGGLE_COOKIE_POLICY_MODAL,
  CLEAR__UPDATE_STRIPE_PROFILE_FAILURE,
  LOGIN_WITH_PAYPAL,
  LOGIN_WITH_PAYPAL_SUCCESS,
  LOGIN_WITH_PAYPAL_FAILURE,
  LOGIN_WITH_PAYPAL_CLEAR,
  ACTIVATE_PARTNERSHIP_CODE,
  ACTIVATE_PARTNERSHIP_CODE_CLEAR,
  ACTIVATE_PARTNERSHIP_CODE_SUCCESS,
  ACTIVATE_PARTNERSHIP_CODE_FAILURE,
} from '../actionTypes'
import { lookupTypes, activationType } from '../../util/enums/enums'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { notificationEnum } from '../../util/enums/notificationEnum'
import { ErrorNotify, SuccessNotify } from '../../helpers/notification'
import { redirectRouteHandler } from '../../helpers/routeHelper'
import { toast } from 'react-toastify'
import { downloadFile, getCookiePolicyStatus, setCookiePolicyStatus } from '../../util/utilFunctions'

const InitialState = {
  isAuthorized: false,
  isAuthenticated: false,
  currentUser: {},
  isError: false,
  errorMessage: '',
  isUpdate: false,
  isUpdateError: false,
  roles: [],

  isMailVerify: false,
  isMailVerifyError: false,
  isMobileVerifySuccess: false,
  isMobileVerifyError: false,

  countryCode: getLocalStorageItem('countryCode'),
  currentLocation: getLocalStorageItem('country'),
  ipAddress: getLocalStorageItem('ipAddress'),
  forgotPasswordMailSent: false,
  isPasswordReset: false,
  isPasswordResetError: false,
  forgotPassword: false,
  forgotPasswordError: false,
  infoList: [],
  userProfileDetail: {},

  preferenceTypes: [],
  professionTypes: [],
  documentTypes: [],
  suggestionTypes: [],
  complaintTypes: [],

  currentUserPaymentInfo: {},
  addedPaymentInfoSuccess: false,
  addedPaymentInfoError: false,

  registerSuccess: false,
  registerFailure: false,

  getUserPaymentInfoSuccess: false,

  requestAgreementIdSuccess: false,
  requestAgreementIdError: false,
  isAgreementCreateSuccess: false,

  getByAgreementIdError: false,
  getByAgreementIdSuccess: false,
  agreementUser: {},
  isLoading: false,
  removeData: false,
  userVerified: false,

  adminLoginAsUserSuccess: false,
  adminLoginAsUserError: false,

  documentDownloadLink: '',
  isAgreementUser: false,

  dealerRequests: [],
  manufacturerDealers: [],

  adminLoginDetected: false,

  managePasswordContentSuccess: false,
  managePasswordContentError: false,
  managePasswordContent: {},
  acceptRejectRequestSuccess: false,
  acceptRejectRequestError: false,

  subscribeLoader: false,
  addRemoveFriendLoader: false,

  userTransactionDetails: [],
  userTransactionDetailsTotal: 0,
  transactionLoader: false,

  userTransactionPayoutsDetails: [],
  userTransactionPayoutsDetailsTotal: 0,
  transactionPayoutsLoader: false,

  threadLoader: false,
  threads: [],
  is_stripe_profile_completed: false,
  stripProfileLink: '',

  isCookiePolicyModalOpen: false,
  cookiePolicyStatus: getCookiePolicyStatus(),
}

export const loginReducer = (state = InitialState, action) => {
  const { currentUser } = state

  switch (action.type) {
    case USER_REGISTER:
      return {
        ...state,
        isError: false,
        isAuthorized: false,
        isAuthenticated: false,
        isAgreementUser: false,
        currentUser: {},
      }

    case USER_REGISTER_SUCCESS:
      const userObject = action.payload.agreement
        ? {
            ...state,
            isAuthorized: false,
            isAuthenticated: false,
            currentUser: {},
            agreementUser: action.payload.agreement,
            registerSuccess: true,
            isAgreementUser: true,
          }
        : {
            ...state,
            isAuthorized: true,
            isAuthenticated: true,
            registerSuccess: true,
            currentUser: action.payload.user,
          }

      return userObject

    case USER_REGISTER_FAILURE:
      return {
        ...state,
        isError: true,
        registerSuccess: false,
        registerFailure: true,
      }

    case CLEAR_USER_REGISTER_FLAG:
      return {
        ...state,
        registerSuccess: false,
        registerFailure: false,
        isError: false,
        isAgreementUser: false,
      }

    case USER_LOGIN:
      return {
        ...state,
        isError: false,
        isAuthorized: false,
        isAuthenticated: false,
        currentUser: {},
      }

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        isAuthenticated: true,
        currentUser: action.payload,
      }

    case USER_LOGIN_FAILURE:
      return {
        ...state,
        isError: true,
      }

    case GET_USER_STRIPE_DETAILS:
      return {
        ...state,
        stripProfileLink: '',
      }

    case GET_USER_STRIPE_DETAILS_SUCCESS:
      return {
        ...state,
        stripProfileLink: action.payload,
      }

    case GET_USER_STRIPE_DETAILS_FAILURE:
      toast.error(action.payload, { autoClose: 5000 })
      return {
        ...state,
        stripProfileLink: '',
      }

    case CLEAR_USER_STRIPE_LINK:
      return {
        ...state,
        stripProfileLink: '',
      }

    case UPDATE_USER_STRIPE_PROFILE_COMPLETED:
      return {
        ...state,
      }

    case UPDATE_USER_STRIPE_PROFILE_COMPLETED_SUCCESS:
      toast.success('Stripe profile updated successfully!', { autoClose: 5000 })
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          is_stripe_profile_completed: action.payload,
        },
      }

    case UPDATE_USER_STRIPE_PROFILE_COMPLETED_FAILURE:
      return {
        ...state,
        stripeProfileError: true,
      }

    case CLEAR__UPDATE_STRIPE_PROFILE_FAILURE:
      return {
        ...state,
        stripeProfileError: false,
      }

    case CLEAR_AUTH_FLAG:
      return {
        ...state,
        isAuthorized: false,
        isError: false,
        isPasswordReset: false,
        errorMessage: '',
      }

    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: {},
        adminLoginDetected: false,
      }

    case FORGOT_PASSWORD_MAIL_SUCCESS:
      return {
        ...state,
        forgotPasswordMailSent: true,
      }

    case FORGOT_PASSWORD_MAIL_FAILURE:
      return {
        ...state,
        isError: true,
        forgotPasswordMailSent: false,
      }

    case CLEAR_FORGOT_PASSWORD_MAIL_FLAG:
      return {
        ...state,
        forgotPasswordMailSent: false,
      }

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordReset: true,
      }

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isPasswordResetError: true,
        isError: true,
      }

    case CLEAR_RESET_PASSWORD_FLAG:
      return {
        ...state,
        isPasswordReset: false,
        isPasswordResetError: false,
      }

    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdate: true,
        currentUser: action.payload,
      }

    case USER_UPDATE_FAILURE:
      return {
        ...state,
        isUpdateError: true,
      }

    case CLEAR_UPDATE_FLAG:
      return {
        ...state,
        isUpdate: false,
        isUpdateError: false,
      }

    case GET_USER_ROLES:
      return {
        ...state,
        roles: [],
      }

    case GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      }

    case GET_ALL_PAGE_INFO_BY_TYPE:
      return {
        ...state,
        infoList: [],
      }

    case GET_ALL_PAGE_INFO_BY_TYPE_SUCCESS:
      return {
        ...state,
        infoList: action.payload,
      }

    case GET_ALL_PAGE_INFO_BY_TYPE_FAILURE:
      return {
        ...state,
        infoList: [],
      }

    case GET_USER_BY_ID:
      return {
        ...state,
        userProfileDetail: {},
        dealerRequestsIds: [],
        isPageLoader: true,
        isUserLoading: true,
      }
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userProfileDetail: action.userProfile ? action.payload : state.userProfileDetail,
        currentUser: action.payload.id === getLocalStorageItem('userId') ? action.payload : state.currentUser,
        isPageLoader: false,
        dealerRequestsIds: action.userProfile
          ? action.payload.dealerRequests.map(item => item.dealer?.id)
          : state.dealerRequestsIds,
        isUserLoading: false,
      }
    case GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        isPageLoader: false,
        isUserLoading: false,
      }

    case SET_DEALER_IN_MANUFACTURER:
      return {
        ...state,
        dealerRequestsIds: [...state.dealerRequestsIds, action.payload?.dealer?.id],
      }

    case USER_EMAIL_VERIFY:
      return {
        ...state,
        isError: false,
        isMailVerify: false,
        isMailVerifyError: false,
        isMobileVerifySuccess: false,
        isMobileVerifyError: false,
      }

    case USER_EMAIL_VERIFY_SUCCESS:
      const message =
        action.activationType === activationType.EMAIL
          ? notificationEnum.EMAIL_VERIFY.message
            ? action.activationType === activationType.MOBILE
            : notificationEnum.EMAIL_VERIFY.message
          : ''

      if (!state.isAuthenticated && !state.currentUser.id) {
        message && SuccessNotify(message)
      }

      return {
        ...state,
        isMailVerify: action.activationType === activationType.EMAIL,
        isMobileVerifySuccess: action.activationType === activationType.MOBILE,
      }

    case USER_EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        isError: true,
        isMailVerify: false,
        isMailVerifyError: action.activationType === activationType.EMAIL,
        isMobileVerifyError: action.activationType === activationType.MOBILE,
      }

    case CLEAR_USER_VERIFY_FLAGS:
      return {
        ...state,
        isMailVerify: false,
        isMailVerifyError: false,
        isMobileVerifySuccess: false,
        isMobileVerifyError: false,
      }

    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      }

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordError: false,
        isError: false,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: true,
        forgotPasswordError: false,
      }

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordError: true,
        isError: true,
      }
    case CLEAR_PASSWORD_FLAG:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordError: false,
      }

    case CHANGE_USER_ACCOUNT_STATUS:
      return {
        ...state,
        statusSuccess: false,
        statusError: false,
      }

    case CHANGE_USER_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        currentUser: { ...currentUser, accountActivated: !currentUser.accountActivated },
        statusSuccess: true,
        statusError: false,
      }

    case CHANGE_USER_ACCOUNT_STATUS_FAILURE:
      return {
        ...state,
        statusSuccess: false,
        statusError: true,
      }

    case GET_USER_LOCATION:
      return {
        ...state,
        countryCode: '',
        currentLocation: '',
        ipAddress: '',
      }

    case GET_USER_LOCATION_SUCCESS:
      const coords = action.payload.geometricLocation?.coordinates

      return {
        ...state,
        currentLocation: action.payload.country,
        countryCode: action.payload.countryCode,
        ipAddress: action.payload.ipAddress,
        geometricLocation: action.payload.geometricLocation,
        latLng: coords && { lat: coords[1], lng: coords[0] },
      }

    case GET_USER_LOCATION_FAILURE:
      return {
        ...state,
      }

    case GET_TYPE_WISE_LOOKUP:
      return {
        ...state,
        preferenceTypes: [],
        professionTypes: [],
        documentTypes: [],
        suggestionTypes: [],
        complaintTypes: [],
      }

    case GET_TYPE_WISE_LOOKUP_SUCCESS:
      return {
        ...state,
        preferenceTypes: action.value === lookupTypes.CONTACT_PREFERENCE ? action.payload : state.preferenceTypes,
        professionTypes: action.value === lookupTypes.PROFESSION ? action.payload : state.professionTypes,
        documentTypes: action.value === lookupTypes.DOCUMENT_TYPES ? action.payload : state.documentTypes,
        suggestionTypes: action.value === lookupTypes.SUGGESTION_TOPIC_AREA ? action.payload : state.suggestionTypes,
        complaintTypes: action.value === lookupTypes.COMPLAINT_ABOUT ? action.payload : state.complaintTypes,
      }

    case GET_USER_PAYMENT_INFO:
      return {
        ...state,
        currentUserPaymentInfo: {},
      }

    case GET_USER_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        getUserPaymentInfoSuccess: true,
        currentUserPaymentInfo: action.payload,
      }

    case ADD_USER_PAYMENT_INFO:
      return {
        ...state,
        addedPaymentInfoSuccess: false,
        addedPaymentInfoError: false,
        currentUserPaymentInfo: {},
      }

    case ADD_USER_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        addedPaymentInfoSuccess: true,
        currentUserPaymentInfo: action.payload || {},
      }

    case ADD_USER_PAYMENT_INFO_FAILURE:
      return {
        ...state,
        addedPaymentInfoError: true,
      }

    case CLEAR_USER_PAYMENT_INFO_FLAG:
      return {
        ...state,
        getUserPaymentInfoSuccess: false,
        addedPaymentInfoSuccess: false,
        addedPaymentInfoError: false,
      }

    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...currentUser,
          email: activationType.EMAIL === action.activationType ? action.payload.user.email : currentUser.email,
          // mobileNumber: activationType.MOBILE === action.activationType ? action.payload.user.mobileNumber : currentUser.mobileNumber,
        },
      }

    case ADD_REQUEST_FOR_AGREEMENT_ID:
      return {
        ...state,
      }

    case ADD_REQUEST_FOR_AGREEMENT_ID_SUCCESS:
      return {
        ...state,
        requestAgreementIdSuccess: true,
        isAgreementCreateSuccess: true,
        isError: false,
      }

    case ADD_REQUEST_FOR_AGREEMENT_ID_FAILURE:
      return {
        ...state,
        requestAgreementIdError: true,
        isError: true,
      }

    case GET_USER_BY_AGREEMENT_ID:
      return {
        ...state,
        agreementUser: {},
        isLoading: true,
      }

    case GET_USER_BY_AGREEMENT_ID_SUCCESS:
      return {
        ...state,
        getByAgreementIdSuccess: true,
        userVerified: true,
        agreementUser: action.payload.getAgreementDetails,
        isLoading: false,
      }

    case GET_USER_BY_AGREEMENT_ID_FAILURE:
      return {
        ...state,
        getByAgreementIdError: true,
        agreementUser: {},
        isLoading: false,
      }

    case CLEAR_AGREEMENT_ID_REQUEST_FLAG:
      return {
        ...state,
        requestAgreementIdSuccess: false,
        requestAgreementIdError: false,
        getByAgreementIdError: false,
        getByAgreementIdSuccess: false,
        isLoading: false,
        isError: true,
      }

    case CLEAR_REMOVE_AGREEMENT_ID_FLAG:
      return {
        ...state,
        removeData: false,
      }

    case CLEAR_AGREEMENT_CREATE_MESSAGE:
      return {
        ...state,
        isAgreementCreateSuccess: false,
      }

    case REMOVE_AGREEMENT_ID_DATA:
      return {
        ...state,
        agreementUser: {},
        removeData: true,
        userVerified: false,
      }

    case ADMIN_LOGIN_AS_USER:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: {},
        adminLoginAsUserSuccess: false,
        adminLoginAsUserError: false,
        adminLoginDetected: true,
      }

    case ADMIN_LOGIN_AS_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload,
        adminLoginAsUserSuccess: true,
        adminLoginDetected: true,
      }

    case ADMIN_LOGIN_AS_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: {},
        adminLoginAsUserError: true,
      }

    case CLEAR_ADMIN_LOGIN_FLAG:
      return {
        ...state,
        adminLoginAsUserSuccess: false,
        adminLoginAsUserError: false,
      }

    case GET_DOCUMENT_URL:
      return {
        ...state,
        documentDownloadLink: '',
      }

    case GET_DOCUMENT_URL_SUCCESS:
      downloadFile(action.payload, action.filename)
      return {
        ...state,
        documentDownloadLink: action.payload,
      }

    case GET_DOCUMENT_URL_FAILURE:
      return {
        ...state,
        documentDownloadLink: '',
      }

    case CLEAR_DOCUMENT_URL:
      return {
        ...state,
        documentDownloadLink: '',
      }
    case GET_ITEMS_BY_USER:
      return {
        ...state,
        // relatedItems: [],
        isPageLoader: true,
        isLoading: true,
        isItemsByUserLoading: (state.isItemsByUserLoading || 0) + 1,
      }
    case GET_ITEMS_BY_USER_SUCCESS:
      return {
        ...state,
        relatedItems: action.payload,
        relatedTotal: action.total,
        isPageLoader: false,
        isLoading: false,
        isItemsByUserLoading: Math.max(0, state.isItemsByUserLoading - 1),
      }
    case GET_ITEMS_BY_USER_FAILURE:
      return {
        ...state,
        relatedItems: [],
        isPageLoader: false,
        isLoading: false,
        isItemsByUserLoading: false,
      }

    case GET_SITE_CONFIGURATIONS:
      return {
        ...state,
        siteConfigurations: [],
      }
    case GET_SITE_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        siteConfigurations: action.payload,
      }
    case GET_SITE_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        siteConfigurations: [],
      }

    case GET_DEALERS_REQUESTS:
      let pendingDealersRequests =
        currentUser.dealerRequests?.length > 0 ? currentUser.dealerRequests.filter(item => !item.accepted && item) : []
      return {
        ...state,
        dealerRequests: pendingDealersRequests,
        dealerRequestsTotal: pendingDealersRequests.total,
      }

    case SET_MANUFACTURER:
      return {
        ...state,
        currentUser: action.payload,
      }

    case GET_MANAGE_PASSWORD_CONTENT:
      return {
        ...state,
        managePasswordContent: {},
      }

    case GET_MANAGE_PASSWORD_CONTENT_SUCCESS:
      return {
        ...state,
        managePasswordContentSuccess: true,
        managePasswordContent: action.payload,
      }
    case GET_MANAGE_PASSWORD_CONTENT_FAILURE:
      return {
        ...state,
        managePasswordContentError: true,
        managePasswordContent: {},
      }

    case ACCEPT_OR_REJECT_DEALER_REQUEST:
      return {
        ...state,
        acceptRejectRequestSuccess: false,
        acceptRejectRequestError: false,
      }

    case ACCEPT_OR_REJECT_DEALER_REQUEST_SUCCESS:
      return {
        ...state,
        acceptRejectRequestSuccess: true,
      }

    case ACCEPT_OR_REJECT_DEALER_REQUEST_FAILURE:
      return {
        ...state,
        acceptRejectRequestError: true,
      }

    case CLEAR_MANUFACTURER_FLAG:
      return {
        ...state,
        acceptRejectRequestSuccess: false,
        acceptRejectRequestError: false,
      }

    //Subscribe to User
    case SUBSCRIBE_USER:
      return {
        ...state,
        subscribeLoader: true,
      }

    case SUBSCRIBE_USER_SUCCESS:
      return {
        ...state,
        subscribeLoader: false,
        userProfileDetail: {
          ...state.userProfileDetail,
          subscribers: action.payload.data,
        },
      }

    case SUBSCRIBE_USER_FAILURE:
      return {
        ...state,
        subscribeLoader: false,
      }

    //add/remove friend from User
    case ADD_REMOVE_FRIEND:
      return {
        ...state,
        addRemoveFriendLoader: true,
      }

    case ADD_REMOVE_FRIEND_SUCCESS:
      return {
        ...state,
        addRemoveFriendLoader: false,
        currentUser: {
          ...state.currentUser,
          friends: action.payload.data,
        },
      }

    case ADD_REMOVE_FRIEND_FAILURE:
      return {
        ...state,
        addRemoveFriendLoader: false,
      }

    //Transaction By User
    case GET_TRANSACTION_BY_USER:
      return {
        ...state,
        transactionLoader: true,
        userTransactionDetails: [],
        userTransactionDetailsTotal: 0,
      }

    case GET_TRANSACTION_BY_USER_SUCCESS:
      return {
        ...state,
        transactionLoader: false,
        userTransactionDetails: action.payload.items,
        userTransactionDetailsTotal: action.payload.total,
      }

    case GET_TRANSACTION_BY_USER_FAILURE:
      return {
        ...state,
        transactionLoader: false,
      }

    //Transaction By User Payouts
    case GET_TRANSACTION_BY_USER_PAYOUTS:
      return {
        ...state,
        transactionPayoutsLoader: true,
        userTransactionPayoutsDetails: [],
        userTransactionPayoutsDetailsTotal: 0,
      }

    case GET_TRANSACTION_BY_USER_PAYOUTS_SUCCESS:
      return {
        ...state,
        transactionPayoutsLoader: false,
        userTransactionPayoutsDetails: action.payload.items,
        userTransactionPayoutsDetailsTotal: action.payload.total,
      }

    case GET_TRANSACTION_BY_USER_PAYOUTS_FAILURE:
      return {
        ...state,
        transactionPayoutsLoader: false,
      }

    //Fetch All Channels By User
    case FETCH_ALL_CHANNELS:
      return {
        ...state,
        threadLoader: true,
        threads: [],
      }

    case FETCH_ALL_CHANNELS_SUCCESS:
      return {
        ...state,
        threadLoader: false,
        threads: action.payload,
      }

    case FETCH_ALL_CHANNELS_FAILURE:
      return {
        ...state,
        threadLoader: false,
      }

    case TOGGLE_COOKIE_POLICY_MODAL:
      return {
        ...state,
        isCookiePolicyModalOpen: action.payload ?? !state.isCookiePolicyModalOpen,
      }

    case SET_COOKIE_POLICY_STATUS:
      setCookiePolicyStatus(action.payload)

      return {
        ...state,
        cookiePolicyStatus: getCookiePolicyStatus(),
      }

    case LOGIN_WITH_PAYPAL:
      return {
        ...state,
        loginWithPaypalLoading: true,
      }

    case LOGIN_WITH_PAYPAL_SUCCESS:
      SuccessNotify('Connected to Paypal successfully')

      return {
        ...state,
        loginWithPaypalSuccess: true,
        currentUser: {
          ...state.currentUser,
          paypalId: action.payload,
        },
      }

    case LOGIN_WITH_PAYPAL_FAILURE:
      ErrorNotify('Cannot connect with Paypal')

      return {
        ...state,
        loginWithPaypalFailure: true,
      }

    case LOGIN_WITH_PAYPAL_CLEAR:
      return {
        ...state,
        loginWithPaypalLoading: false,
        loginWithPaypalSuccess: false,
        loginWithPaypalFailure: false,
      }

    case ACTIVATE_PARTNERSHIP_CODE:
      return {
        ...state,
        activate_partnershipCode_loading: true,
        activate_partnershipCode_failure: false,
        activate_partnershipCode_failure_errMsg: null,
      }

    case ACTIVATE_PARTNERSHIP_CODE_SUCCESS:
      return {
        ...state,
        activate_partnershipCode_success: true,
      }

    case ACTIVATE_PARTNERSHIP_CODE_FAILURE:
      return {
        ...state,
        activate_partnershipCode_failure: true,
        activate_partnershipCode_failure_errMsg: action.payload.errMsg,
      }

    case ACTIVATE_PARTNERSHIP_CODE_CLEAR:
      return {
        ...state,
        activate_partnershipCode_loading: false,
        activate_partnershipCode_success: false,
      }

    default:
      return state
  }
}
