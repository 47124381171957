import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { ErrorMessage, Formik, Form } from 'formik'
import { Grid, Card, Box, CardContent } from '@material-ui/core'
import { Row, Col } from 'react-bootstrap'

import { Loader, Field } from '../../components'
import GoogleMap from '../../components/map/map'
import { cancelHandler } from '../../helpers/routeHelper'
import { requireMessage, getIds, requiredWords, validateWords, trimmedValue, maximumWordCount } from '../../helpers/string'
import { helpTextEnum, AddressInputTypeEnum, MEDIA_NAME } from '../../util/enums/enums'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import {
  addBoatService,
  clearEditFlag,
  getUserBoatService,
  getAllBoatServiceTypes,
  clearErrorMessageShow,
} from '../../redux/actions'
import { renderSelectOptions } from '../../helpers/string'
import MultiSelect from '../../components/helper/multiSelect'

import './boatServices.scss'
import ErrorComponent from '../../components/error/errorComponent'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { getLatLngFromGeometric } from '../../helpers/jsxHelper'
import { ButtonComponent } from '../../components/form/Button'
import { getAddMedia } from '../../helpers/s3FileUpload'
import { stringValidation, urlValidation } from '../../helpers/yupHelper'
import TagsInput from '../dashboard/manageBoats/TagsInput'
import { clear_getAgreement, getAgreement } from '../../redux/actions/pageInfoByTypeAction'
import { CommonModal } from '../../components/modal/commonModal'
import { toast } from 'react-toastify'
import { get } from 'lodash'

class AddBoatService extends Component {
  constructor(props) {
    super(props)
    const { userService } = props
    this.state = {
      selected: [],
      latLng: {},
      boatService: {
        address: [],
        service: '',
        // reviews: '',
        featuresAndAdvantages: '',
        // teamCommitment: '',
        qualityOfService: '',
        serviceProvide: [],
        youtubeUrl: '',
        images: userService ? userService.images : [],
        addMedia: {
          images: [],
        },
        removeMedia: [],
        meta_tags: null,
      },
      mediaUpdate: false,
      oldSwp: [],
      isButtonLoader: false,
      agree: true,
      userRole: '',
      isAgreementModalOpen: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { isAddSuccess, isAddError, history, clearEditFlag, currentUser, getAgreement, clear_getAgreement, agreement } =
      nextProps

    if (currentUser?.role?.aliasName && currentUser?.role?.aliasName !== state.userRole) {
      getAgreement({ role: currentUser?.role?.aliasName })

      return {
        ...state,
        userRole: currentUser?.role?.aliasName,
      }
    }

    if (agreement?.success || agreement?.failure) {
      clear_getAgreement()
      return state
    }

    if (isAddSuccess) {
      clearEditFlag()
      history.push('/dashboard')
    }
    if (isAddError) {
      return {
        isButtonLoader: false,
      }
    }

    return null
  }

  componentDidMount() {
    const { getAllBoatServiceTypes, getUserBoatService } = this.props
    getAllBoatServiceTypes({ isAdmin: true })
    getUserBoatService()
  }

  handleChange = e => {
    this.setState({ selected: e.target.value })
  }

  prepareServiceValue = () => {
    const { userService } = this.props
    const { boatService, mediaUpdated } = this.state

    if (userService && userService.hasOwnProperty('id')) {
      if (!mediaUpdated) {
        const { address, service, reviews, featuresAndAdvantages, teamCommitment, qualityOfService, youtubeUrl, images } =
          userService

        boatService.service = service
        // boatService.reviews = reviews
        boatService.featuresAndAdvantages = featuresAndAdvantages
        // boatService.teamCommitment = teamCommitment
        boatService.qualityOfService = qualityOfService
        boatService.youtubeUrl = youtubeUrl
        boatService.images = images

        const [oldAddress] = address

        this.setState({
          boatService,
          latLng: getLatLngFromGeometric(oldAddress.geometricLocation),
          mediaUpdated: true,
        })
      }
      // delete extra field from boat-service object
      const { status, adStatus, createdAt, __typename, user, ...filteredUserService } = userService

      if ('__typename' in get(filteredUserService, "address[0]", {})) {
        delete filteredUserService.address[0].__typename
      }
      if ('__typename' in get(filteredUserService, "address[0].geometricLocation", {})) {
        delete filteredUserService.address[0].geometricLocation.__typename
      }

      return {
        ...filteredUserService,
        addMedia: boatService.addMedia,
        removeMedia: [],
      }
    } else {
      return boatService
    }
  }

  toggleAgreementModal = () => {
    this.setState(prevState => ({
      isAgreementModalOpen: !prevState.isAgreementModalOpen,
    }))
  }

  renderInfo = (setFieldValue, values) => {
    let { providedAdminServices } = this.props

    providedAdminServices = renderSelectOptions(providedAdminServices, 'name', 'id')

    return (
      <Card className="card-style h-100 m-0 box-shadow-none map-div border-top-right-radius-0 border-top-left-radius-0 border-bottom-right-radius-2 border-bottom-left-radius-2 overflow-card-visible">
        <CardContent className="h-100">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label className="required">Describe Your Services</label>
              <textarea
                rows={4}
                className="form-control"
                placeholder="Describe Your Services"
                name="service"
                value={values.service}
                onChange={e => setFieldValue('service', (e.target.value).substring(0, 700))}
                onBlur={e => setFieldValue('service', trimmedValue(e.target.value))}
              />
              <div className="d-flex justify-content-between align-items-center">
                <span><ErrorMessage component="div" name="service" className="error-message" /></span>
                <span>{values.service?.length || 0}/700</span>
              </div>
            </Grid>

            {/* <Grid item xs={12}>
              <label className="mt-3 required">Team Commitment</label>
              <textarea
                rows={4}
                className="form-control"
                placeholder="Team Commitment"
                name="teamCommitment"
                value={values.teamCommitment}
                onChange={e => setFieldValue('teamCommitment', e.target.value)}
                onBlur={e => setFieldValue('teamCommitment', trimmedValue(e.target.value))}
                onKeyDown={maximumWordCount}
              />
              <ErrorMessage component="div" name="teamCommitment" className="error-message" />
            </Grid> */}

            {/* <Grid item xs={6}>
              <label className="required">Reviews</label>
              <textarea
                className="form-control"
                placeholder="Reviews"
                name="reviews"
                value={values.reviews}
                onChange={e => setFieldValue('reviews', e.target.value)}
                onBlur={e => setFieldValue('reviews', trimmedValue(e.target.value))}
                onKeyDown={maximumWordCount}
              />
              <ErrorMessage component="div" name="reviews" className="error-message" />
            </Grid> */}

            <Grid item xs={12}>
              <label className="mt-3 required">Our Quality Of Service</label>
              <textarea
                rows={4}
                className="form-control"
                placeholder="Our Quality Of Service"
                name="qualityOfService"
                value={values.qualityOfService}
                onChange={e => setFieldValue('qualityOfService', (e.target.value).substring(0, 500))}
                onBlur={e => setFieldValue('qualityOfService', trimmedValue(e.target.value))}
              />
              <div className="d-flex justify-content-between align-items-center">
                <span><ErrorMessage component="div" name="qualityOfService" className="error-message" /></span>
                <span>{values.qualityOfService?.length || 0}/500</span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <label className="mt-3 required">Our features and advantages</label>
              <textarea
                rows={4}
                className="form-control "
                placeholder="Our features and advantages"
                name="featuresAndAdvantages"
                value={values.featuresAndAdvantages}
                onChange={e => setFieldValue('featuresAndAdvantages', (e.target.value).substring(0, 500))}
                onBlur={e => setFieldValue('featuresAndAdvantages', trimmedValue(e.target.value))}
              />
              <div className="d-flex justify-content-between align-items-center">
                <span><ErrorMessage component="div" name="featuresAndAdvantages" className="error-message" /></span>
                <span>{values.featuresAndAdvantages?.length || 0}/500</span>
              </div>
            </Grid>

            <Grid item xs={12} className="boat-service-drop-down multi-select-chip-margin">
              <label className="mt-3 required">Service We Provide </label>
              <MultiSelect
                helpText={helpTextEnum.multiSelect}
                selectedOption={values.serviceProvide}
                onChangeValue={item => {
                  setFieldValue('serviceProvide', [...item])
                }}
                options={providedAdminServices}
              />
              <ErrorMessage component="div" name="serviceProvide" className="error-message" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  renderUploadPictures = (setFieldValue, values) => {
    const { boatService } = this.state

    return (
      <Card
        className="card-style h-100 m-0 box-shadow-none map-div border-top-right-radius-0 border-top-left-radius-0
      border-bottom-right-radius-2 border-bottom-left-radius-2"
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                label="Upload Pictures"
                id="images"
                name="images"
                type="multi-upload-image"
                setFieldValue={setFieldValue}
                values={values.images}
                deleteIds={values.removeMedia}
                addMedia={values.addMedia}
                mediaName={MEDIA_NAME.YACHTIMAGE}
                required
                maxFiles={15}
              />
              <ErrorMessage component="div" name="images" className="error-message text-center" />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} className="mb-3">
                <Box className="font-sm-label" fontSize={20} letterSpacing={1} fontWeight={600}>
                  Add video link
                </Box>
              </Grid>

              <div className="font-bold dark-silver">You can add Youtube, Facebook, Daily Motion, Streamable, Google Videos, and Vimeo</div>
              <input
                type="text"
                className="form-control"
                placeholder="Insert video link"
                name="youtubeUrl"
                value={values.youtubeUrl}
                onChange={e => setFieldValue('youtubeUrl', e.target.value)}
              />
              <ErrorMessage component="div" name="youtubeUrl" className="error-message" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  cancelHandler = () => {
    const { history, clearEditFlag } = this.props
    clearEditFlag()
    cancelHandler(history)
  }

  render() {
    const { latLng, boatService, isButtonLoader } = this.state
    const { addBoatService, userService, isLoading, isAddError, errorMessage, agreement: agreementContent } = this.props
    const initValue = userService ? this.prepareServiceValue() : boatService

    return (
      <DashboardLayout className="fix-service-add-layout">
        <div className="pr-3 pl-3">
          {isLoading ? (
            <Loader isPageLoader />
          ) : (
            <Formik
              initialValues={{ ...initValue }}
              validationSchema={Yup.object().shape({
                address: Yup.array(
                  Yup.object({
                    city: Yup.string(),
                  })
                )
                  .compact(v => !v.city || !v.state || !v.country)
                  .required(requireMessage('Service Location'))
                  .min(1, requireMessage('Service Location'))
                  .ensure(),
                service: stringValidation
                  .required(requireMessage('Description'))
                  .ensure()
                  .max(700, 'Max. 700 characters allowed')
                  .test('match', requiredWords('Description'), val => validateWords(val)),
                // teamCommitment: stringValidation.required(requireMessage('Team Commitment')),
                // reviews: stringValidation.required(requireMessage('Review')),
                qualityOfService: stringValidation.required(requireMessage('Quality Of Service')).max(500, 'Max. 500 characters allowed'),
                featuresAndAdvantages: stringValidation.required(requireMessage('Features and advantages')).max(500, 'Max. 500 characters allowed'),
                serviceProvide: Yup.array().min(4, 'Minimum 4 service we provide select required'),
                images: Yup.array().min(5, requireMessage('Minimum 5 photos upload')).required(requireMessage('Picture')).max(15, 'You can add up to 15 images'),
                youtubeUrl: urlValidation,
              })}
              onSubmit={values => {
                if (!this.state.agree) {
                  toast.error(`Please agree to the service agreement`, { autoClose: 5000 })
                  return
                }

                this.setState({ isButtonLoader: true })
                const { clearErrorMessageShow } = this.props
                clearErrorMessageShow()

                const { __typename, createdAt, rating, status, adStatus, images, ...serviceData } = values

                const input = {
                  ...serviceData,
                  serviceProvide: getIds(serviceData.serviceProvide),
                  addMedia: getAddMedia(values.addMedia),
                }

                addBoatService(input)
              }}
              render={({ errors, setFieldValue, values, handleSubmit }) => (
                <Form className='adam-dash-ser-main'>
                  <ErrorFocus />
                  <div className="pl-2 pt-3 pb-3">
                    <Box fontSize={24} fontWeight={700}>
                      Move your store to AdamSea to create your business page
                      {values?.adId && <div className="mt-2 font-weight-400 font-14">Ad ID: {`${values.adId}`}</div>}
                    </Box>
                  </div>

                  <Row className="mb-3 base--align-item">
                  <Col xs={6} className='adam-dash-ser-left'>
                      <Row className="m-0">
                      <Col xs={12} className="pl-0 adam-dash-ser-map">
                          <div className="add-boat-map map-div-form add--service-location">
                            <GoogleMap
                              latLng={latLng}
                              height={35}
                              width={100}
                              placeHolder="Where is your office located"
                              columnName="address"
                              fetch={result => {
                                if (result?.address1) {
                                  setFieldValue('address', [{ ...result, type: AddressInputTypeEnum.COMMON_ADDRESS }])
                                } else {
                                  setFieldValue('address', [])
                                }
                              }}
                              isError={errors.address}
                              isUpdate={values.hasOwnProperty('address') && values.address}
                              isFullGrid
                            ></GoogleMap>
                          </div>
                        </Col>

                        <Col xs={12} className="pl-0 mt-3 adam-dash-ser-upload-img">
                          {this.renderUploadPictures(setFieldValue, values)}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6} className="pl-0 adam-dash-ser-right">
                      {this.renderInfo(setFieldValue, values)}
                      <div className="mt-2 adam-dash-ser-meta px-3">
                        <TagsInput
                          value={values?.meta_tags}
                          handleChange={value => {
                            setFieldValue('meta_tags', value.join(','))
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  {isAddError && <ErrorComponent errors={errorMessage} />}

                  {/* <div style={{ marginTop: '1em' }}>
                    {agreementContent?.data && (
                      <>
                        <div className="clearfix">
                          <div className="custom-control custom-checkbox  mb-none custom--radio--btn">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="agreement"
                              checked={this.state.agree}
                              onChange={e => this.setState({ agree: e.target.checked })}
                            />
                            <label htmlFor="agreement" className="custom-control-label font-14 custom--radio--btn">
                              I have read and agree to{' '}
                              <span
                                className="darkBlue cursor-pointer"
                                onClick={e => {
                                  e.stopPropagation()
                                  this.toggleAgreementModal()
                                }}
                              >
                                {agreementContent.data.title}
                              </span>{' '}
                              and understand that this application is used to add Rent Boat with AdamSea.Com.
                            </label>
                          </div>
                        </div>

                        <CommonModal
                          open={this.state.isAgreementModalOpen}
                          close={this.toggleAgreementModal}
                          title={agreementContent.data.title}
                          className="agreement--service--modal--section"
                        >
                          <div dangerouslySetInnerHTML={{ __html: agreementContent.data.pageContent }} />
                        </CommonModal>
                      </>
                    )}
                  </div> */}

                  <div
                    className="d-flex justify-content-center main--add--forms--btn mt-4 mb-5 add--service--btn"
                    style={{ marginTop: '1em' }}
                  >
                    <button
                      className="btn btn-lg btn-o-black mr-3"
                      disabled={isButtonLoader}
                      loader={isButtonLoader}
                      onClick={handleSubmit}
                    >
                      List my business
                    </button>

                    <button
                      className="btn btn-lg btn-o-black"
                      onClick={() => this.cancelHandler()}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            ></Formik>
          )}
        </div>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  isAddSuccess: state.boatServiceReducer && state.boatServiceReducer.isAddSuccess,
  isAddError: state.boatServiceReducer && state.boatServiceReducer.isAddError,
  providedAdminServices: state.boatServiceReducer && state.boatServiceReducer.providedAdminServices,
  userService: state.boatServiceReducer && state.boatServiceReducer.userService,
  isLoading: state.boatServiceReducer && state.boatServiceReducer.isLoading,
  errorMessage: state.errorReducer.errorMessage,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  agreement: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.agreement,
})

const mapDispatchToProps = dispatch => ({
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getAllBoatServiceTypes: data => dispatch(getAllBoatServiceTypes(data)),
  addBoatService: data => dispatch(addBoatService(data)),
  getUserBoatService: () => dispatch(getUserBoatService()),
  clearEditFlag: () => dispatch(clearEditFlag()),
  getAgreement: data => dispatch(getAgreement(data)),
  clear_getAgreement: () => dispatch(clear_getAgreement()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBoatService)
