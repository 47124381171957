import React, { Component } from 'react'
import Truncate from 'react-truncate'

import { CommonAdamSeaStyle } from '../styleComponent/styleComponent'
import { getHeightRatio, getRatio } from '../../helpers/ratio'
import { dimension } from '../../util/enums/enums'
import { redirectRouteHandler } from '../../helpers/routeHelper'
import { getDomainUrl } from '../../util/utilFunctions'

class RegisterCard extends Component {
  createMarkup = data => {
    return { __html: data }
  }
  state = {
    height: 300,
    width: 1600,
  }
  ratioWidth = () => {
    const { isDouble } = this.props
    const enumName = isDouble ? 'marinaRegisterDouble' : 'marinaRegister'
    const width = getRatio(dimension, enumName, '.section-heading')
    const height = getHeightRatio(dimension, 'marinaRegister', '.section-heading')
    this.setState({ width, height })
  }
  componentDidMount() {
    this.ratioWidth()
    window.addEventListener('resize', this.ratioWidth)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.ratioWidth)
  }
  render() {
    const { adData, history, isSpace, isSpace2 } = this.props
    const { height, width } = this.state
    return (
      <div className={`register-card-main ${adData?.link ? 'cursor-pointer' : ''}`}>
        <div className="mt-50 advertisement--spacing">
          <div
            className="row"
            onClick={() => {
              adData?.link && window.open(getDomainUrl(adData.link), '_blank')
            }}
          >
            <div className='col-12'>
              <CommonAdamSeaStyle
                className={`w-100 register--card--ads ${isSpace ? 'register--card--ads-l' : isSpace2 ? 'register--card--ads-r' : ''}`}
                img={encodeURI(adData.thumbnail?.length > 0 && adData.thumbnail[0].largeUrl)}
                width={width}
                height={height}
                isCardDouble={this.props.isDouble ? 'yes' : 'no'}
              >
                <div className="d-flex register-card">
                  <div className="h2">{adData.title}</div>

                  <p className="margin-bottom-advertise advertisement--description">
                    <Truncate lines={2} ellipsis={<span>..</span>}>
                      <div
                        className="register-description ml-"
                        dangerouslySetInnerHTML={this.createMarkup(adData.description)}
                      />
                    </Truncate>
                  </p>
                  <div
                    className="register-link-name cursor-pointer hover-text-only"
                    onClick={() => {
                      adData.link && redirectRouteHandler(getDomainUrl(adData.link), false)
                    }}
                  >
                    {adData.linkTitle}
                  </div>
                </div>
              </CommonAdamSeaStyle>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RegisterCard
RegisterCard.default = {
  isDouble: false,
  isSpace2: false,
  isSpace: false,
}
