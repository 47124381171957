import styled from 'styled-components'
import { displayDefaultImage } from '../../helpers/string'

const ServicesStyle = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 0 0 10px;
`

const ServiceTextStyle = styled.div`
  width: calc(100% - ${props => props.actualWidth}px);
`

const RentCardStyle = styled.div`
  width: ${props => props.width}px;
`

const RentBannerImage = styled.div`
  width: calc(100% - ${props => props.width}px);
`

const RentHomeCardBanner = styled.div`
  height: calc(100% - ${props => props.height}px);
`

/** Marina */
const RecommendedStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px 10px;
`
/** Marina */
const MostPopularContentStyle = styled.div`
  width: ${props => props.bgWidth}px;
`

const LogInSignupBanner = styled.div`
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
`

const MoreServiceStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
const MoreServiceTextStyle = styled.div`
  width: ${props => props.bgWidth}px;
`
const MarinaVideoStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const RentVideoStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const BoatShowVideoStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: ${props => props.bgWidth}px;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const BoatServiceVideoStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const HomPageVideoStyle = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SellAroundStyle = styled.div`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  background-image: url(${props => (props.img ? props.img : displayDefaultImage)});
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  margin-bottom: ${props => props.margin}px;
  margin-right: ${props => props.margin}px;
  border-radius: 10px;
`
const ManufacturerStyle = styled.div`
  height: ${props => props.height}px;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
`
const CommonAdamSeaStyle = styled.div`
  aspect-ratio: 16/${props => props.isCardDouble === 'yes' ? '5' : props.isCardDouble === 'no' ? '3' : '10'};
  background-image: url('${props => props.img}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 3px;
`
const MostPopularStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px 10px;
`
const AuctionRoomStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px 10px 0 0;
  padding: 0 10px;
  position: relative;
`

const HomeRecommendedContentStyle = styled.div`
  height: ${props => props.bgHeight}px;
  /*width:${props => props.bgWidth}px;*/
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px 10px;
`
const HomeMostPopularContentStyle = styled.div`
  width: ${props => props.bgWidth}px;
`
const CommonBannerStyle = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
const ArrowCarousel = styled.div`
  top: ${props => props.top}px !important;
  background-image: url(${props => props.img});
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff !important;
  border-radius: 50%;
  position: absolute;
`

const BoatMediaMainStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: ${props => props.bgWidth}px;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px 10px;
`
const BoatMediaWidthStyle = styled.div`
  width: ${props => props.bgWidth}px;
`
const ShowAllGridStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px 10px;
`
const ServiceCardTextStyle = styled.div`
  width: calc(100% - ${props => props.width}px);
`
const BoatInnerStyle = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 6px 6px;
`
const FirstBannerStyle = styled.div`
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 0px 0px 10px;
  height: 100%;
`
const SecondBannerStyle = styled.div`
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
`
const ThirdBannerStyle = styled.div`
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
`
const BoatServiceCarouselStyle = styled.div`
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px 6px 6px 6px;
`
const LeftArrowStyle = styled.div`
  background-image: url(${props => props.img});
  background-size: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  width: 100%;
  background-position: center;
`
const RightArrowStyle = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  width: 100%;
  background-position: center;
`
const BoatShowBannerFormStyle = styled.div`
  height: 100%;
  width: ${props => props.bgWidth}px;
`

const BoatServiceBannerFormStyle = styled.div`
  height: 100%;
  width: ${props => props.bgWidth}px;
`
const BoatMediaBannerFormStyle = styled.div`
  height: 100%;
  width: ${props => props.bgWidth}px;
`
const BannerHeight = styled.div`
  height: calc(100vh - ${props => props.height}px);
`
const SectionHeadingShowStyle = styled.div`
  position: fixed;
  width: 100%;
  z-index: 99999;
  top: ${props => props.top}px;
  top: inherit;
`
const SectionWithShowMapStyle = styled.div`
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: 100%;
`
const SectionWithShowMapStyleBoat = styled.div`
  width: ${props => props.width}px;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: ${props => props.height}px;
`
const BoatInnerGallery = styled.div`
  position: relative;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
`
const MapPlaceInfoStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: ${props => props.bgWidth}px;
  cursor: pointer;
`
const MapPlaceImgStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
`
const InnerBannerLikesDiv = styled.div`
  bottom: ${props => props.bottom}px !important;
`
const BoatInnerRelatedVideoStyle = styled.div`
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MapShowStyle = styled.div`
  top: ${props => props.top}px !important;
  height: calc(100vh - ${props => props.heightPx}px) !important;
`
const StepperBoatInfoStyle = styled.div`
  height: ${props => props.bgHeight}px;
  width: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px 10px;
`
const BoatServiceItemStyle = styled.div`
  height: 100%;
  width: 100%;
`
const MapShowStyleGrid = styled.div`
  top: ${props => props.top}px !important;
  height: calc(100vh - ${props => props.top}px) !important;
`
const SectionWithShowMapStyleBoatCarousal = styled.div`
  width: ${props => props.width}px;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: ${props => props.height}px;
`
const RentGridStyle = styled.div`
  width: ${props => props.width}px;
  margin-right: ${props => props.margin}px !important;
`
const UserProfileBoatsStyle = styled.div`
  width: ${props => props.width}px;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: ${props => props.height}px;
`
const MapBoatGridStyleGrid = styled.div`
  width: calc(100% - ${props => props.width}px) !important;
`

const MapMarinaGridStyleGrid = styled.div`
  width: calc(100% - ${props => props.width}px) !important;
`

const MapServiceGridStyleGrid = styled.div`
  width: calc(100% - ${props => props.width}px) !important;
`

const MapShowGridStyleGrid = styled.div`
  width: calc(100% - ${props => props.width}px) !important;
`
const BackgroungImage = styled.div`
  background-image: url(${props => props.img});
`
const SkeletonGridStyle = styled.div`
  margin-right: ${props => props.margin}px;
  margin-bottom: ${props => props.margin}px;
`
const SkeletonMapGridStyle = styled.div`
  width: calc(100% - ${props => props.width}px);
  height: ${props => props.height}px;
`
const NextIconCarrer = styled.div`
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

const labelRequired = styled.div`
  top: ${props => props.top}px !important;
  position: absolute;
`

const StyledSubscribeBtn = styled.button`
  display: flex;
  align-items: center;
  background-color: #0f0f0f;
  padding: 0 16px;
  height: 36px;
  font-size: 11px;
  color: #ffffff;
  border: 0;
  line-height: 36px;
  border-radius: 18px;
`
const StyledTransparentBtn = styled.button`
  color: ${props => props.color || '#ffffff'};
  border-radius: 6px;
  border: 1px solid ${props => props.color || '#ffffff'};
  padding: 2px 4px;
  font-size: ${props => props.textSize || "14"}px;
  background-color: transparent;
  &:hover {
    box-shadow: 1px 1px 2px
  }
`
export {
  ServicesStyle,
  ServiceTextStyle,
  RecommendedStyle,
  MoreServiceStyle,
  MarinaVideoStyle,
  MoreServiceTextStyle,
  SellAroundStyle,
  ManufacturerStyle,
  CommonAdamSeaStyle,
  MostPopularStyle,
  MostPopularContentStyle,
  AuctionRoomStyle,
  HomeRecommendedContentStyle,
  HomeMostPopularContentStyle,
  HomPageVideoStyle,
  CommonBannerStyle,
  ArrowCarousel,
  BoatMediaMainStyle,
  BoatMediaWidthStyle,
  RentVideoStyle,
  BoatShowVideoStyle,
  BoatServiceVideoStyle,
  ShowAllGridStyle,
  ServiceCardTextStyle,
  BoatInnerStyle,
  FirstBannerStyle,
  SecondBannerStyle,
  ThirdBannerStyle,
  BoatServiceCarouselStyle,
  LeftArrowStyle,
  RightArrowStyle,
  BoatShowBannerFormStyle,
  RentCardStyle,
  RentBannerImage,
  BoatServiceBannerFormStyle,
  BoatMediaBannerFormStyle,
  BannerHeight,
  RentHomeCardBanner,
  SectionHeadingShowStyle,
  SectionWithShowMapStyle,
  BoatInnerGallery,
  MapPlaceInfoStyle,
  MapPlaceImgStyle,
  InnerBannerLikesDiv,
  BoatInnerRelatedVideoStyle,
  MapShowStyle,
  StepperBoatInfoStyle,
  LogInSignupBanner,
  BoatServiceItemStyle,
  MapShowStyleGrid,
  SectionWithShowMapStyleBoat,
  SectionWithShowMapStyleBoatCarousal,
  RentGridStyle,
  UserProfileBoatsStyle,
  MapBoatGridStyleGrid,
  MapMarinaGridStyleGrid,
  MapServiceGridStyleGrid,
  MapShowGridStyleGrid,
  BackgroungImage,
  SkeletonGridStyle,
  SkeletonMapGridStyle,
  NextIconCarrer,
  labelRequired,
  StyledSubscribeBtn,
  StyledTransparentBtn
}
