import { gql } from 'apollo-boost'
import {
  Boat,
  Yacht,
  Marina,
  BoatRent,
  LookUp,
  SuccessResponse,
  AdminUser,
  SellerUser,
  RatingReview,
  Like,
  PageVisit,
  AddressType,
  TypeCrud,
  ImageType,
  BoatCardResponse,
  Role,
  Media,
} from './schemaTypes/schemaTypes'

export const AuctionRoomBidsResponse = `{
  id
  price
  user {
    id
    firstName
    lastName
    email
    address{
      country
    }
  }
  status
  createdAt
}`

export const AuctionPaymentsResponse = `{
  id
  paymentId
  country
  paymentType: sub_category_id
  transactionType
  stripeFee
  amount
}`

export const AuctionRoomResponse = `{
  id
  startTime
  endTime
  bidDuration
  inspectionCost
  startPrice
  minimumRaisedAmount
  auctionStartingBid
  auctionCurrentBid
  payment ${AuctionPaymentsResponse}
  auctionBids ${AuctionRoomBidsResponse}
  boat {
    id
    adId
    images ${Media}
    boatName
    price
    yearBuilt
    boatType ${TypeCrud}
  }
  auctionWinner {
    id
    firstName
    lastName
    email
    address ${AddressType}
  }
  status
  createdAt
  depositAmount
  depositAdded
}`

export const boatByCitiesSchema = gql`
  query getBoatByCities($id: String!, $page: Int, $limit: Int) {
    boatByCities(id: $id, page: $page, limit: $limit) {
      items ${BoatCardResponse}
      total
    }
  }
`

export const getBoatLookUps = gql`
  {
    getBoatLookUps {
      typeName
      lookUp {
        id
        alias
        lookUpName
      }
    }
  }
`

export const getBoatTypes = gql`
query getAllBoatTypes($roleType: String, $isAllTypes: Boolean,$limit:Int,$page:Int) {
  getAllBoatTypes(roleType: $roleType, isAllTypes: $isAllTypes,limit:$limit,page:$page) {
     items{
      id
      name
      icon ${Media}
      createdAt
      updatedAt
     }
    }
  }
`

export const getBoatsByType = gql`
  query getBoatsByType($input: BoatTypes!, $page: Int, $limit: Int, $latLng: CoordinateInput) {
    getBoatsByType(input: $input, page: $page, limit: $limit, latLng: $latLng) {
      items ${BoatCardResponse}
      total
    }
  }
`

export const getLatestBoats = gql`
  query getLatestBoats($page: Int, $limit: Int, $latLng: CoordinateInput) {
    getLatestBoats(page: $page, limit: $limit, latLng: $latLng) {
      items ${BoatCardResponse}
      total
    }
  }
`
export const getMostPopularBoats = gql`
  query getMostPopularBoats($page: Int, $limit: Int, $latLng: CoordinateInput) {
    getMostPopularBoats(page: $page, limit: $limit, latLng: $latLng) {
      items ${BoatCardResponse}
      total
    }
  }
`

export const getTopRatedBoats = gql`
  query getTopRatedBoats($page: Int, $limit: Int, $latLng: CoordinateInput) {
    getTopRatedBoats(page: $page, limit: $limit, latLng: $latLng) {
      items ${BoatCardResponse}
      total
    }
  }
`

export const getAllBoatByUser = gql`
  query getAllBoatByUser($page: Int, $limit: Int, $sort: SortType, $sortKey: String) {
    getAllBoatByUser(page: $page, limit: $limit, sort:$sort, sortKey: $sortKey) {
      items  {
        id
        adId
        boatName
        price
        adStatus
        isAuctionRoomCreated
        isSalesEngineCreated
        winnerAssigned
        yearBuilt
        salesEngineStatus
        boatType ${TypeCrud}
        manufacturedBy ${LookUp}
        engineHp
        seller {
            id
            role ${Role}
        }
        boatStatus ${LookUp}
        auctionRoom {
          status
        }
        createdAt
        stockQuantity
        isEnabledForAuction
        salesEngineStatus
      }
      total
    }
  }
`

export const addBoat = gql`
  mutation createBoat($boat: BoatInput!) {
    createBoat(boat: $boat) {
      id
    }
  }
`

export const searchBoat = gql`
  mutation searchBoat($input: BoatSearchInput!) {
    searchBoat(input: $input) {
      items ${BoatCardResponse}
      total
    }
  }
`

export const getBoatsSearchValues = gql`
  {
    getBoatsSearchValues {
      minPrice
      maxPrice
      minYearBuild
      maxYearBuild
      minLengthInFt
      maxLengthInFt
    }
  }
`
export const getUsersByCountryQuety = gql`
query getUsersByCountry($roleType: String, $boatId: String = null, $isVerified: Boolean, $country: String) {
  getUsersByCountry(roleType: $roleType, boatId: $boatId, isVerified: $isVerified, country: $country) {
    items {
      address ${AddressType}
      isVerified
      like
      title
      firstName
      lastName
      shortDescription
      companyWebsite
      mobileNumber
      companyName
      companyLogo ${Media}
      token
      id
      email
      branch {
        id
        branchName
        image ${Media}
        address ${AddressType}
        distance
        pricePerFt
        isMainBranch
        contactName
      }

      image ${Media}
      reviews ${RatingReview}
    }
    total
  }
}
`

export const multiSearch = gql`
  query multiSearch($query: String!, $isAdmin: Boolean = false) {
    multiSearch(query: $query, isAdmin: $isAdmin) {

      boats ${Boat}

      usedBoats ${Boat}

      newBoats ${Boat}

      rentBoats ${BoatRent}

      yachtService ${Yacht}

      marinaStorage ${Marina}
    }
  }
`

export const getRecentSearch = gql`
  {
    getRecentSearch {
      id
      query
      createdAt
    }
  }
`

export const editBoat = gql`
  query editBoat($id: String!, $basic: Boolean) {
    editBoat(boatId: $id, basic: $basic) {
      id
      address ${AddressType}
      adStatus
      featureStatus
      bestDealStatus
      mustBuyStatus
      status
      accidentDescribe
      boatReview
      complianceLabelNumber
      hullSerialNumber
      vehicleIdentificationNumber
      licenceNo
      pageVisits ${PageVisit}
      ownershipAttachment ${ImageType}
      boatOwnershipId ${ImageType}
      isTaxEnabled
      internationalWarranty
      internationalWarrantyValidity
      internationalWarrantyTerms
      tax
      weightCapacity
      kind ${LookUp}
      inStock
      stockQuantity
      listedBy
      boatType ${TypeCrud}
      kayakType ${LookUp}
      boatStatus ${LookUp}
      boatName
      boatParking ${LookUp}
      trailer
      seaTrial
      yearBuilt
      manufacturedBy ${LookUp}
      hullMaterial ${LookUp}
      hullColor ${LookUp}
      usedHours
      noOfEngines
      modelYear
      fuelType ${LookUp}
      seats
      fuelCapacity
      holdingCapacity
      freshWater
      engineManufacturer
      engineModel
      engineHp
      engineDrives ${LookUp}
      engineStroke ${LookUp}
      waterMarker
      bowThruster
      steeringSystem
      stabilizerSystem
      oilWaterSeparator
      fireBilgePump
      output
      generators
      emergencyGenerator
      batteriesCount
      batteryType
      decks
      heightInFt
      lengthInFt
      widthInFt
      weightInKg
      beam
      draft
      displacement
      mainSaloon ${LookUp}
      mainSaloonConvertible ${LookUp}
      numberOfHeads
      crewCabinCount
      crewBerthCount
      crewHeadsCount
      amenities ${LookUp}
      accessories ${LookUp}
      navigationEquipments ${LookUp}
      usage
      accidentHistory
      repairHistory
      lastMaintenance
      price
      description
      images ${Media}
      layout ${Media}
      video
      reviews ${RatingReview}
      rating
      rating_count
      adId
      likes ${Like}
      isSalesEngineCreated
      isAuctionRoomCreated
      auctionApproved
      auctionDeclined
      winnerAssigned
      totalSurveyors
      totalShippers
      mechanicalSystemLength
      meta_tags
      electricalSystemLength
      auctionRoomWinner {
        id
        firstName
        email
      }
      salesEngineStatus
      salesEngine {
        id
        buyer {
          id
          firstName
        }
      }
      minPurchaseQuantity
    }
  }
`

export const editBoat_seller = gql`
  query editBoat_seller($boatId: String!) {
    editBoat_seller(boatId: $boatId) ${SellerUser}
  }
`

export const boatChangeStatus = gql`
  mutation boatChangeStatus($id: String!, $columnName: String!, $value: Boolean!) {
    boatChangeStatus(id: $id, columnName: $columnName, value: $value)
      ${SuccessResponse}
  }
`

export const deleteBoat = gql`
  mutation deleteBoat($id: String!) {
    deleteBoat(id: $id) {
      id
      boatName
    }
  }
`

export const updateBoat = gql`
  mutation updateBoat($input: BoatInput!) {
    updateBoat(boat: $input) {
      id
    }
  }
`

export const getCityWiseBoats = gql`
  query getCityWiseBoats($page: Int, $limit: Int, $city: String!, $country: String!, $latLng: CoordinateInput) {
    getCityWiseBoats(page: $page, limit: $limit, city: $city, country: $country, latLng: $latLng) {
      items ${BoatCardResponse}
      total
    }
  }
`

export const categoryWiseBoats = gql`
  query categoryWiseBoats($page: Int, $limit: Int, $categoryId: String!, $latLng: CoordinateInput $isManufacturer: Boolean) {
    categoryWiseBoats(page: $page, limit: $limit, categoryId: $categoryId, latLng: $latLng, isManufacturer: $isManufacturer) {
      items ${BoatCardResponse}
      total
    }
  }
`

export const createAuctionRoom = gql`
  mutation createAuctionRoom($auctionRoom: AuctionRoomInput!) {
    createAuctionRoom(auctionRoom: $auctionRoom) {
      id
    }
  }
`

export const getUserAuctionRooms = gql`
  query getAuctionRoomsByUser($page: Int!, $limit: Int!, $sort: SortType, $sortKey: String, $searchTerm: String) {
    getAuctionRoomsByUser(page: $page, limit: $limit, sort: $sort, sortKey: $sortKey, searchTerm: $searchTerm) {
      items ${AuctionRoomResponse}
      total
    }
  }
`

export const getBoatAuctionRoom = gql`
  query getBoatAuctionRoom($id: String!, $includeClosed: Boolean) {
    getBoatAuctionRoom(id: $id, includeClosed: $includeClosed)
      ${AuctionRoomResponse}
  }
`

export const checkForQuantityQuery = gql`
  query checkForQuantity($boatId: String!) {
    checkForQuantity(boatId: $boatId) {
      id
      salesEngineStatus
      salesEngine {
        id
        buyer {
          id
          firstName
        }
      }
    }
  }
`


export const getAuctionAllBids = gql`
  query getAuctionBids($page: Int!, $limit: Int!, $auctionId: String!) {
    getAuctionBids(page: $page, limit: $limit, auctionId: $auctionId) {
      items {
        auctionRoom {
          auctionWinner {
            id
            firstName
            lastName
          }
          boat {
            id
            boatName
            price
          }
          id
          startTime
          endTime
          startPrice
          minimumRaisedAmount
          status
          createdAt
        }
        id
        price
        status
        user {
          id
          firstName
          lastName
          companyName
        }
        createdAt
      }
      total
    }
  }
`

export const getAllAuctionRooms = gql`
query getAllAuctionRoom($page: Int!, $limit: Int!) {
  getAllAuctionRoom(page: $page, limit: $limit) {
    items {
      boat ${BoatCardResponse}
      id
      startPrice
      endTime
      startTime
      auctionCurrentBid
      status
      createdAt
    }
    total
  }
}
`

export const getBannerByModuleQuery = gql`
 query getBannerByModule(
    $input : GetMarketMediaInput!
  ) {
    getBannerByModule(
        input : $input,
        limit: 100
    ){
       items{
        id
        type
        metatype
        mediaPosition
        url ${ImageType}
        title
        role
        mediaOrder
        link
        thumbnail ${ImageType}
        isBanner
       }
    }
}`

export const getUserDashBoardCountQuery = gql`
  {
    getUserDashBoardCount {
      auctionRoom
      boatShow
      article
      manageHomeAdvertisement
      boat
      auctionBidList
      broker
      boatRent
      surveyorBranches
      salesEngineMyRequestCount
      marina
      salesEngine
      serviceStatus
      salesEngineCount
      salesEngineArchiveCount
      salesEngineBuyItNowCount
      salesEngineAgentCount
      manufacturerCounts
      dealersDeals
      brokerCount
      rentBookingType
    }
  }
`

export const createAuctionBidQuery = gql`
  mutation createAuctionBid($input: AuctionBidInput!) {
    createAuctionBid(input: $input) {
      id
      price
      status
    }
  }
`

export const getCostEstimation = gql`
query getCostEstimation($id: String!) {
  getCostEstimation(id: $id) {
    pdf ${ImageType}
    boatPrice
    totalPrice
    surveyorName
    adminPrice
    paymentTransferFee
    tax
    taxFee
    surveyorPrice
    surveyorCost
    boatData {
      adId
      listedBy
      boatLocation
      boatStatus
      images ${Media}
    }
  }
}
`

export const sendBoatCostEstimationPdf = gql`
  mutation sendBoatCostEstimationPdf($email: String!, $id: String!) {
    sendBoatCostEstimationPdf(email: $email, id: $id) {
      message
    }
  }
`

export const getAuctionByBidUser = gql`
query getAuctionBidByUser($page: Int, $limit: Int, $sort: SortType, $sortKey: String, $searchTerm: String) {
  getAuctionBidByUser(page: $page, limit: $limit, sort: $sort, sortKey: $sortKey, searchTerm: $searchTerm) {
    id
    createdAt
    auctionRoom {
      boat {
        id
        boatName
        boatType {
          id
          name
        }
        price
        manufacturedBy ${LookUp}
        yearBuilt
        engineHp
        seller{
          id
          firstName
          lastName
          role {
            aliasName
          }
        }
        adId
      }
      startTime
      endTime
      bidDuration
      auctionStartingBid
      auctionCurrentBid
      inspectionCost
      startPrice
      minimumRaisedAmount
      auctionWinner {
        id
        firstName
        lastName
      }
      status
    }
    price
    user{
      id
      firstName
      lastName
    }
  }
}
`

export const getBoatConvertedCostEstimation = gql`
  mutation getBoatConvertedCostEstimation($input: EstimateCostInput!) {
    getBoatConvertedCostEstimation(input: $input)
  }
`

export const getAuctionSalesEngine = gql`
  query getAuctionSalesEngine($id: String!) {
    getAuctionSalesEngine(id: $id)
  }
`

export const makeAuctionPayment = gql`
  mutation makeAuctionPayment($input: AuctionPaymentInput!) {
    makeAuctionPayment(input: $input) {
      message
      id
      data
    }
  }
`

export const footerLinkBoats = gql`
  query footerLinkBoats($page: Int, $limit: Int, $type: ROLE, $mostViewed: Boolean) {
    footerLinkBoats(page: $page, limit: $limit, type: $type, mostViewed: $mostViewed) {
      items {
        id
        price
        boatType {
          name
        }
        boatName
        lengthInFt
        seller {
          firstName
          lastName
          role {
            aliasName
          }
        }
        address {
          city
          country
          state
          geometricLocation {
            type
            coordinates
          }
        }
        rating
        modelYear
        images {
          smallUrl
          url
        }
        boatStatus ${LookUp}
      }
      total
    }
  }
`

export const getYachtsQuery = gql`
  query getYachts($page: Int, $limit: Int) {
    getYachts(page: $page, limit: $limit) {
      items ${BoatCardResponse}
      total
    }
  }
`
