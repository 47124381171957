import gql from 'graphql-tag'
import {
  GeometricLocation,
  TypeCrud,
  SellerUser,
  AdminUser,
  LookUp,
  Marina,
  Role,
  Like,
  PageVisit,
  RatingReview,
  DocumentVerification,
  AddressType,
  ImageType,
  MarinaCardResponse,
  Media,
  PaymentMethodTypes,
  ServiceProvide,
} from './schemaTypes/schemaTypes'

export const addMarinaAndStorage = gql`
  mutation createMarina($input: MarinaInput!) {
    createMarina(input: $input) {
      id
    }
  }
`

export const getAllMarinaStorage = gql`
  {
    getMarinaList {
      id
      address ${AddressType}
      provider ${LookUp}
      noneMembersRate
      membershipBenefits
      promotionRunning
      name
      berth
      facilities
      storage
      membersRate
      serviceProvide ${TypeCrud}
      # serviceDescription
      images ${ImageType}
      accountStatus
      createdAt
      rating
      user {
        firstName
        lastName
        companyName
        companyLogo ${ImageType}
      }
    }
  }
`

export const getTypeWiseLookup = gql`
  query getTypeWiseLookup($type: String!) {
    getTypeWiseLookup(type: $type) {
      id
      alias
      typeId {
        id
        type
      }
      lookUpName
      tripId {
        id
        alias
      }
    }
  }
`

export const getMarinasByType = gql`
  query getMarinasByType(
    $page: Float!
    $limit: Int!
    $typeId: String!,
    $latLng: CoordinateInput
  ) {
    getMarinasByType(
      page: $page
      limit: $limit
      typeId: $typeId,
      latLng: $latLng
    ) {
      items {
        id
        address ${AddressType}
        provider ${LookUp}
        serviceProvide ${TypeCrud}
        user {
          firstName
          lastName
          companyName
          companyLogo  ${ImageType}
        }
        noneMembersRate
        membershipBenefits
        name
        promotionRunning
              berth
        facilities
        storage
        membersRate
        images ${ImageType}
        accountStatus
        createdAt
        rating
      }
      total
    }
  }
`
export const marinaStorageByUser = gql`
  query marinaStorageByUser($page: Int, $limit: Int, $sort: SortType, $sortKey: String, $searchTerm: String) {
    marinaStorageByUser(page: $page, limit: $limit, sort: $sort, sortKey: $sortKey, searchTerm: $searchTerm) {
      items ${MarinaCardResponse}
      total
    }
  }
`
export const getRecentlyAddedMarinaStorage = gql`
  query nearestMarinaByCountry(
    $page: Int!
    $limit: Int!
    $latLng: CoordinateInput
  ) {
    nearestMarinaByCountry(
      page: $page
      limit: $limit
      latLng: $latLng
    ) {
      items {
        id
        address ${AddressType}
        provider ${LookUp}
        noneMembersRate
        membershipBenefits
        promotionRunning
              name
        berth
        facilities
        storage
        membersRate
        # serviceDescription
        images ${ImageType}
        accountStatus
        adStatus
        createdAt
        adId
        rating
        serviceProvide ${TypeCrud}
        user {
          firstName
          lastName
          companyName
          companyLogo ${ImageType}
          image ${ImageType}
        }
      }

      total
    }
  }
`

export const getSingleMarinaStorage = gql`
  query editMarina($id: String!, $basic: Boolean) {
    editMarina(id: $id, basic: $basic) {
      id
      adStatus
      address ${AddressType}
      provider ${LookUp}
      noneMembersRate
      dockLayout ${ImageType}
      storageLayout ${ImageType}
      membershipBenefits
      promotionRunning
      name
      pageVisits ${PageVisit}
      likes ${Like}
      berth
      facilities
      serviceProvide ${ServiceProvide}
      # serviceDescription
      storage
      membersRate
      youtubeLinkVideo
      images ${ImageType}
      accountStatus
      createdAt
      adId
      user {
        createdAt
        address ${AddressType}
        like
        title
        firstName
        lastName
        companyWebsite
        mobileNumber
        paymentMethodTypes ${PaymentMethodTypes}
        language
        companyName
        companyLogo ${ImageType}
        token
        id
        email
        language
        isVerified
        accountActivated
        documentVerification ${DocumentVerification}

        provider ${LookUp}

        role ${Role}
        image ${ImageType}
        governmentId ${ImageType}
        commercialLicence ${ImageType}

        reviews ${RatingReview}
      }
      reviews ${RatingReview}
      meta_tags
    }
  }
`

export const updateMarinaStorage = gql`
  mutation updateMarina($input: MarinaInput!) {
    updateMarina(input: $input) {
      id
    }
  }
`

export const deleteMarinaStorage = gql`
  mutation deleteMarina($id: String!) {
    deleteMarina(id: $id) {
      id
    }
  }
`

export const getMostViewedMarinaStorage = gql`
  query getMostPopularMarina(
    $page: Int!
    $limit: Int!
    $latLng: CoordinateInput
  ) {
    getMostPopularMarina(page: $page, limit: $limit, latLng: $latLng) {
      items {
        id
        address ${AddressType}
        provider ${LookUp}
        serviceProvide ${TypeCrud}
        noneMembersRate
        membershipBenefits
        promotionRunning
              name
        berth
        facilities
        storage
        membersRate
        images ${ImageType}
        adId
        rating
        user ${AdminUser}
      }
      total
    }
  }
`

export const searchMarinaStorage = gql`
  mutation searchMarinaStorage($input: MarinaSearchInput!) {
    searchMarinaStorage(input: $input) {
      items {
        id
        address ${AddressType}
        provider ${LookUp}
        noneMembersRate
        membershipBenefits
        promotionRunning
              name
        berth
        facilities
        storage
        serviceProvide ${TypeCrud}
        membersRate
        images ${ImageType}
        accountStatus
        createdAt
        adId
        createdAt
        rating
        user ${AdminUser}
      }
      total
    }
  }
`

export const getMoreServicesQuery = gql`
  query getAllMarinaTypes($isAdmin: Boolean = false) {
    getAllMarinaTypes(isAdmin: $isAdmin) {
      items{
          id
          name
          icon ${ImageType}
          createdAt
          updatedAt
      }
   }
  }
`

export const getTopRatedMarinaStorageQuery = gql`
  query getTopRatedMarinaList(
    $page: Int
    $limit: Int
    $latLng: CoordinateInput
  ) {
    getTopRatedMarinaList(page: $page, limit: $limit, latLng: $latLng) {
      items {
        id
        address ${AddressType}
        provider ${LookUp}
        serviceProvide ${TypeCrud}
        noneMembersRate
        membershipBenefits
        promotionRunning
            name
          berth
        facilities
        storage
        membersRate
        images ${ImageType}
        adId
        pageVisits ${PageVisit}
        rating
        user ${AdminUser}
      }
      total
    }
  }`
export const getMarinaStorageServiceListQuery = gql`
  query marinaStorageByService(
    $page: Int
    $limit: Int
    $serviceId: String!,
    $latLng: CoordinateInput
  ) {
    marinaStorageByService(page: $page, limit: $limit, serviceId: $serviceId, latLng: $latLng) {
      items {
        id
        images ${ImageType}
        address ${AddressType}
        rating
        reviews ${RatingReview}
        provider ${LookUp}
        serviceProvide ${TypeCrud}
        user{
          image ${ImageType}
          firstName
          lastName
          companyName
          companyLogo ${ImageType}
        }
        membersRate
        name
        facilities
        pageVisits ${PageVisit}
      }
      total
    }
  }`

export const getSimilarMarinaList = gql`
  query getSimilarMarinaList(
    $id: String!
    $page: Int
    $limit: Int
    $latLng: CoordinateInput
    ){
    getSimilarMarinaList(
      id: $id
      page: $page
      limit: $limit
      latLng: $latLng
      ){
      items ${Marina}
      total
    }
  }`

export const marinaChangeMarketStatus = gql`
  mutation marinaChangeMarketStatus($input: MarinaMarketStatusInput!) {
    marinaChangeMarketStatus(input: $input) {
      id
    }
  }
`
